import { getBenefits, getInsuranceExtraInfoText } from "~/helpers/hardware-and-insurance/insuranceActionsHelper";

export const mapInsuranceProducts = ({ products, benefits }) => {
	const mappedProducts = products.map((insuranceProduct) => {
		return mapInsuranceProduct({ insuranceProduct, benefits });
	});

	return mappedProducts.sort((a, b) => {
		return a.sortOrder - b.sortOrder;
	});
};

export const mapInsuranceProduct = ({ insuranceProduct, benefits }) => {
	const gizmoInfo = getBenefits({
		benefits: benefits,
		isDownPayment: insuranceProduct?.paymentTypes?.includes("DOWN_PAYMENT"),
		isScreen: insuranceProduct.publicName?.includes("Skjermforsikring"),
	});

	const selectedInfoText = getInsuranceExtraInfoText({
		selectedInfoText: gizmoInfo.selectedInsuranceInfoText,
		poid: insuranceProduct.productId,
	});

	return {
		...insuranceProduct,
		badge: gizmoInfo?.badge,
		benefit: gizmoInfo?.benefits,
		preSelected: gizmoInfo?.preSelected,
		selectedInfoText,
	};
};
