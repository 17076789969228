export default [
	"openid",
	"profile",
	"ial2",
	"api:mobile-usage-v2:read",
	"api:mobile-subscription-v1",
	"api:productfeasibility-v1:read",
	"api:productfeasibility-v2",
	"api:address-search-v1",
	"api:fixed-consumer-v1:self-service",
	"api:fixed-email-v1",
	"api:fixed-subscription-v2",
	"api:fixed-subscription-v1",
	"api:fixed-order-v1",
	"api:fixed-order-v2",
	"api:incident-status-v1:getAddressIncidentStatus",
	"api:fixed-order-search-v2",
	"api:family-bonus-v1",
	"api:subscriptions-v1",
	"api:customers-v1:external",
	"api:mobile-order-v2:self-service-web",
	"api:mobile-sim-service-v1",
	"api:mobile-product-v2:read",
	"api:mobile-product-v2:postProductOrder",
	"api:bank-id-identification-v1",
	"api:mobile-order-search-v1:getSimcardOrders",
	"api:handsets-v1",
	"api:invoice-control-v2:enduser",
	"api:invoices-v1",
	"api:payment-connect-v1",
	"api:fixed-invoice-v1",
	"api:tv-content-v1",
	"tnn.ids",
	"api:ecommerce-products-v2:public",
	"api:ecommerce-products-v3",
	"api:ecommerce-shipping-v1:checkAvailability",
	"api:esim-v1",
	"api:netspayment-v1",
	"api:agreements-v2:self-service-web",
	"api:email-subscriptions-v1:self-service",
	"api:device-return-v1:self-service-web",
	"api:tv-subscription-v1",
	"api:id-theft-administration-v1",
	"api:mobile-data-roaming-cutoff-v2",
	"api:mobile-messaging-bully-v1",
	"api:voice-mail-configuration-v1",
	"api:mobile-messaging-configuration-v1",
	"api:alternative-contact-addresses-v2",
	"api:nettvern-report-v1",
	"api:mobile-agreements-v1",
	"api:mobile-traffic-configuration-v1",
	"api:invites-v1",
	"api:rdc-registry-v1",
	"api:hardware-subscriptions-v1:self-service",
	"api:voice-mail-message-v1",
	"api:id-guard-v1:self-service",
	"api:security-subscriptions-v1:self-service",
	"api:debt-management-v1:self-service",
	"api:consumer-fault-search-v1:self-service",
	"api:web-segments-v1",
	"api:device-return-v2:self-service-web",
	"api:streamix-v1:user",
	"api:mobile-self-service-v1:get-orders",
	"api:fixed-save-v1:mtw-save",
	"api:mobile-self-service-v1:tnweb",
];
