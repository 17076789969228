/** Todo: Replace any with Component and Layout only Gizmo modulification is merged */

export default function useSegmentLabels(component: any, layout: any) {
    const segmentation = computed(() => {
        if(component) return component.segmentation;
        if(layout) return layout.segmentation;
    });

    const mustSegments = computed(() => {
        let mustSegments = [];
        if(useRuntimeConfig().public.previewMode && useGizmoStore()?.labels){
            if(segmentation.value?.must) mustSegments = [...segmentation.value.must];
            if(segmentation.value?.device?.must) mustSegments = [...mustSegments, ...segmentation.value.device.must];
        }
        return mustSegments;
    });

    const mustNotSegments = computed(() => {
        let mustNotSegments = [];
        if(useRuntimeConfig().public.previewMode && useGizmoStore()?.labels){
            if(segmentation.value?.mustNot) mustNotSegments = [...segmentation.value.mustNot];
            if(segmentation.value?.device?.mustNot) mustNotSegments = [...mustNotSegments, ...segmentation.value.device.mustNot];
        }
        return mustNotSegments;
    });

    return {
        segmentation,
        mustSegments,
        mustNotSegments
    }
}