import apigeeIntegration from "./self-service-adapter";
import systemUserIntegration from "~/integrations/self-service-system-adapter";
import systemUserCaptchaIntegration from "~/integrations/self-service-system-recaptcha";

const api = apigeeIntegration("/mobile-order/v2");
const systemUserApi = systemUserIntegration("/mobile-order/v2");
const systemUserCaptchaApi = systemUserCaptchaIntegration("/mobile-order/v2");

const getOrderWithoutBankId = (orderObject) => {
	return orderObject; // this function is redundant now and should be removed
};

const getSummary = async (orderObject) =>
	api.post("/order/validate", getOrderWithoutBankId(orderObject), {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now,
		},
		cache: { override: true },
		timeout: 1000 * 50,
	});

const getSummaryForLoggedOutUsers = async (orderObject) =>
	systemUserApi.post("/order/validate/", getOrderWithoutBankId(orderObject), {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now,
			"x-telenor-channel-name": "MTW",
			"x-apicache-bypass": true,
		},
		cache: { override: true },
		timeout: 1000 * 50,
	});

const sendOrder = async (orderObject) => {
	return api.post("/order", getOrderWithoutBankId(orderObject), {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
		},
		cache: { override: true },
		timeout: 60000,
	});
};

const validateOrder = async (order) => {
	return await api.post("/order/validate", order, {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
		},
		cache: { override: true },
		timeout: 1000 * 50,
	});
};

const softCreditCheck = async () => {
	return await api.get("/creditcheck/soft", {
		headers: {
			"x-telenor-dealer-id": 6662,
		},
		cache: { override: true },
	});
};

const sendOrderForLoggedOutUsers = async (orderObject) => {
	return systemUserApi.post("/order/", getOrderWithoutBankId(orderObject), {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
			"x-telenor-channel-name": "MTW",
		},
	});
};

const reserveNumbers = () =>
	api.get("/numbers/reserve?accessType=VOICE&amount=3", {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
			"x-telenor-channel-name": "MTW",
		},
		cache: { override: true },
	});

const reserveNumbersForProduct = (productId) =>
	api.get(`/numbers/reserve?productId=${productId}&amount=1`, {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
			"x-telenor-channel-name": "MTW",
		},
		cache: { override: true },
	});

const reserveNumbersForLoggedOutUsers = (rcToken) =>
	systemUserCaptchaApi.get("/numbers/reserve/?accessType=VOICE&amount=3", {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
			"x-telenor-channel-name": "MTW",
			"x-apicache-bypass": true,
		},
		cache: { override: true },
		rcToken,
	});

const validPortTimes = (withPostageTime) =>
	api.get(`/order/validporttimes?withPostageTime=${withPostageTime}`, {
		headers: {
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
		},
		cache: { override: true },
	});

const getQrCodesForOrderId = (orderId) =>
	api.get(`/esim/orderId/${orderId}/qrcodes`, {
		headers: {
			"Content-Type": "application/json",
			"x-telenor-dealer-id": 6662, // fix for system user issue right now
		},
		cache: { override: true },
	});

export default {
	getSummary,
	sendOrder,
	validateOrder,
	softCreditCheck,
	getSummaryForLoggedOutUsers,
	sendOrderForLoggedOutUsers,
	reserveNumbers,
	reserveNumbersForLoggedOutUsers,
	reserveNumbersForProduct,
	validPortTimes,
	getQrCodesForOrderId,
};
