import {
	getContactDetailsWithKurtIdFromHeader,
	updateContactDetailsWithKurtFromHeader,
} from "~/ts-integrations/customers-v1";

interface IContactDetails {
	phoneNumber?: string;
	subscribingToSmsNewsLetter: boolean;
	emailAddress?: string;
	subscribingToEmailNewsLetter: boolean;
	telemarketingApproved: boolean;
	directMarketingApproved: boolean;
	customerDataExtraditionApproved: boolean;
	telemarketingBrregApproved: boolean;
	directMarketingBrregApproved: boolean;
}

interface IUserMessage {
	message: string;
	success: boolean;
	error?: Error;
}

export const useContactDetailsStore = defineStore("contactDetails", () => {
	const loading: Ref<boolean> = ref(false);
	const contactDetails: Ref<IContactDetails | null> = ref(null);
	const error: Ref<Error | null> = ref(null);

	const emailAddress: ComputedRef<string> = computed(() => contactDetails.value?.emailAddress || "");
	const phoneNumber: ComputedRef<string> = computed(() => contactDetails.value?.phoneNumber || "");

	async function fetchContactDetails(): Promise<IContactDetails | IUserMessage> {
		loading.value = true;
		try {
			const { data: response } = await getContactDetailsWithKurtIdFromHeader();
			contactDetails.value = response;

			return contactDetails.value;
		} catch (e) {
			error.value = e;
			return {
				message: "Vi klarte dessverre ikke å hente din kundeinformasjon",
				error: e,
				success: false,
			};
		} finally {
			loading.value = false;
		}
	}

	async function updateContactDetails(newContactDetails): Promise<IUserMessage> {
		loading.value = true;

		newContactDetails = Object.fromEntries(Object.entries(newContactDetails).filter(([_, v]) => v != null));

		try {
			const { data: response } = await updateContactDetailsWithKurtFromHeader({ body: newContactDetails });
			contactDetails.value = response;

			return {
				message: "Din kundeinformasjon er oppdatert",
				success: true,
			};
		} catch (e) {
			error.value = e;
			return {
				message: "Ops! Vi klarte dessverre ikke å oppdatere din kundeinformasjon",
				error: e,
				success: false,
			};
		} finally {
			loading.value = false;
		}
	}

	return {
		loading,
		contactDetails,
		error,
		emailAddress,
		phoneNumber,
		fetchContactDetails,
		updateContactDetails,
	};
});
