/**
 * @file
 * This file contains Lodash functions in native
 *
 * Currently, it contains:
 *
 * compact - Native
 *
 * includes - Native
 *
 * pickBy - Native
 *
 * uniqBy - Native
 *
 * omitBy - Native
 *
 * isNil - Native
 *
 * isEqual - Native
 *
 */

// Thanks, ChatGPT
export function merge(object, source) {
	function baseMerge(obj, src) {
		for (let key in src) {
			if (src.hasOwnProperty(key)) {
				const objValue = obj[key];
				const srcValue = src[key];

				if (typeof objValue === "object" && objValue !== null && typeof srcValue === "object" && srcValue !== null) {
					obj[key] = baseMerge(objValue, srcValue);
				} else {
					obj[key] = srcValue;
				}
			}
		}
		return obj;
	}

	return baseMerge(object, source);
}

export function unionBy(arrays, iteratee) {
	const seen = new Map();
	const result = [];

	const getIterateeValue = (value) => {
		return typeof iteratee === "function" ? iteratee(value) : value[iteratee];
	};

	arrays.flat().forEach((value) => {
		const criterion = getIterateeValue(value);
		if (!seen.has(criterion)) {
			seen.set(criterion, value);
			result.push(value);
		}
	});

	return result;
}

// Thanks, ChatGPT
export function mergeWith(object, source, customizer) {
	if (typeof customizer !== "function") {
		throw new Error("Customizer should be a function");
	}

	function baseMerge(obj, src) {
		for (let key in src) {
			if (src.hasOwnProperty(key)) {
				const objValue = obj[key];
				const srcValue = src[key];
				const result = customizer(objValue, srcValue, key, obj, src);

				if (result === undefined) {
					// If customizer returns `undefined`, do default behavior
					if (typeof objValue === "object" && typeof srcValue === "object") {
						obj[key] = baseMerge(objValue, srcValue);
					} else {
						obj[key] = srcValue;
					}
				} else {
					obj[key] = result;
				}
			}
		}
		return obj;
	}

	return baseMerge(object, source);
}

/**
 * To use omit do { a, c, ...result2 } = object instead
 *
 * To use includes to .includes() instead
 */

/**
 * @function
 *
 * @param {object} object - the object you want to pick from
 * @param {function} predicate - The function invoked per property.
 * @return {object}
 */
export const pickBy = (object, predicate = (v) => v) =>
	Object.entries(object)
		// eslint-disable-next-line no-unused-vars
		.filter(([_, v]) => predicate(v))
		.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const isNil = (value) => value == null;

/**
 * @function
 *
 * @param {array} array - The array to inspect.
 * @param {key} key - the key to be uniq
 * @return {array}
 */
export const uniqBy = (array, key) => {
	return Object.values(
		[...array].reverse().reduce((m, i) => {
			m[key.split(".").reduce((a, p) => a?.[p], i)] = i;
			return m;
		}, {}),
	);
};

/**
 * @function
 *
 * @param {object} object - the object you want to pick from
 * @param {function} predicate - The function invoked per property.
 * @return {object}
 */
export const omitBy = (object, predicate = (v) => v) =>
	Object.entries(object)
		// eslint-disable-next-line no-unused-vars
		.filter(([_, v]) => !predicate(v))
		.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

/**
 * @function
 *
 * @param {*} first - The value to compare.
 * @param {*} second - The other value to compare.
 * @return {boolean} - Returns true if the values are equivalent, else false.
 */
export const isEqual = (first, second) => {
	if (first === second) {
		return true;
	}
	if ((first === undefined || second === undefined || first === null || second === null) && (first || second)) {
		return false;
	}
	const firstType = first?.constructor.name;
	const secondType = second?.constructor.name;
	if (firstType !== secondType) {
		return false;
	}
	if (firstType === "Array") {
		if (first.length !== second.length) {
			return false;
		}
		let equal = true;
		for (let i = 0; i < first.length; i++) {
			if (!isEqual(first[i], second[i])) {
				equal = false;
				break;
			}
		}
		return equal;
	}
	if (firstType === "Object") {
		let equal = true;
		const fKeys = Object.keys(first);
		const sKeys = Object.keys(second);
		if (fKeys.length !== sKeys.length) {
			return false;
		}
		for (let i = 0; i < fKeys.length; i++) {
			if (first[fKeys[i]] && second[fKeys[i]]) {
				if (first[fKeys[i]] === second[fKeys[i]]) {
					continue;
				}
				if (
					first[fKeys[i]] &&
					(first[fKeys[i]].constructor.name === "Array" || first[fKeys[i]].constructor.name === "Object")
				) {
					equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
					if (!equal) {
						break;
					}
				} else if (first[fKeys[i]] !== second[fKeys[i]]) {
					equal = false;
					break;
				}
			} else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
				equal = false;
				break;
			}
		}
		return equal;
	}
	return first === second;
};
/**
 * Creates an array with all falsey values removed. The values `false`, `null`,
 * `0`, `""`, `undefined`, and `NaN` are falsey.
 *
 * @static
 * @memberOf _
 * @category Array
 * @param {Array} array The array to compact.
 * @returns {Array} Returns the new array of filtered values.
 * @example
 *
 * _.compact([0, 1, false, 2, '', 3]);
 * // => [1, 2, 3]
 */
export function compact(array) {
	let index = -1;
	let resIndex = 0;
	const length = array == null ? 0 : array.length;
	const result = [];

	while (++index < length) {
		const value = array[index];
		if (value) {
			result[resIndex++] = value;
		}
	}
	return result;
}