import { usePageStore } from "~/pinia/platform/page";
export default function () {
	const isTest = useIsTest();
	const pageStore = usePageStore();
	const page: { template?: { ref?: string }; metadata?: { disableChat?: boolean } } = pageStore?.page;
	const disableChat =
		page?.metadata?.disableChat ||
		useNuxtApp().$config.public.disableChat ||
		page.template.ref === "tweBoxWebApp" ||
		useRoute()?.path?.includes("/twe-box");

	if (!disableChat) {
		if (isTest) {
			return useScript({
				src: "https://chat-client.web.test.apc.telenor.net/client.js",
				crossorigin: false,
				referrerpolicy: false,
			});
		} else {
			return useScript({
				src: "https://chat.telenor.no/client.js",
				crossorigin: false,
				referrerpolicy: false,
			});
		}
	}
}
