<template>
	<div
		class="section-layout"
		:class="[layoutClasses, { compact }]"
	>
		<div
			v-for="(column, colIndex) in segmentedLayout"
			:key="`col-${colIndex}`"
		>
			<ClientOnly v-if="previewMode && column.length === 0">
				<div class="component empty">
					<LazyAddComponentOrSection
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="0"
						:first="colIndex === 0 && compIndex === 0 && index === 0"
						above
					/>
					<TnButton
						v-if="previewMode"
						@click.stop="handleAddComponentClicked(colIndex)"
						>Add component
					</TnButton>
					<LazyAddComponentOrSection
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="0"
						last
						below
					/>
				</div>
			</ClientOnly>
			<div
				class="component"
				v-for="(component, compIndex) in column"
				:key="`dropzone-${colIndex}-${compIndex}`"
			>
				<ClientOnly v-if="previewMode">
					<LazyAddComponentOrSection
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="compIndex"
						:first="colIndex === 0 && compIndex === 0 && index === 0"
						above
					/>
				</ClientOnly>
				<slot
					:colIndex="colIndex"
					:compIndex="compIndex || 0"
					:component="component"
					:isLast="compIndex === column.length - 1"
				/>
				<ClientOnly v-if="previewMode">
					<!-- Inline editing: Empty column placeholders are always visible -->
					<LazyAddComponentOrSection
						:always-visible="column.length === 0"
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="0"
						v-if="column.length === 0"
						:last="compIndex === column.length - 1"
					/>
					<LazyAddComponentOrSection
						v-else-if="column.length > 0"
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="compIndex"
						below
						:last="compIndex === column.length - 1"
					/>
				</ClientOnly>
			</div>
		</div>
		<ClientOnly v-if="previewMode">
			<LazyGizmoDeleteBtn
				:data-path="dataPath"
				class="delete-layout"
				v-if="showGizmoDeleteButton"
			/>
		</ClientOnly>
	</div>
</template>
<script>
import { mapState as mapVuexState } from "vuex";
import { mapState as mapPiniaState } from "pinia"; // temporary "as" alias to avoid conflict with vuex
import { usePageStore } from "~/pinia/platform/page.ts";

export default defineNuxtComponent({
	name: "TLayout",

	props: {
		layout: { type: Object },
		segmentedLayout: { type: Array },
		index: { type: Number },
		dataPath: { type: String, default: "" },
	},

	methods: {
		handleAddComponentClicked(colIndex) {
			parent.postMessage(
				{
					action: "addComponent",
					payload: {
						layoutIdx: this.index,
						colIdx: colIndex,
						compIdx: 0,
					},
				},
				"*",
			);
		},
	},

	computed: {
		...mapVuexState(["isPreview"]),
		...mapPiniaState(usePageStore, ["page"]),
		layoutClasses() {
			if (this.page.metadata?.layoutConfig) {
				return getLayoutClasses(this.page.metadata.layoutConfig.numberOfColumns);
			}
			return getLayoutClasses(this.layout.columns.length);
		},
		compact() {
			return isCompact(this.page.template?.id);
		},
		previewMode() {
			return this.$config.public.previewMode;
		},
		showGizmoDeleteButton() {
			return this.previewMode && this.layout.columns && this.editable && this.layout.columns.flat().length < 1;
		},
		editable() {
			return this.$config.public.previewMode; // && !this.$store.state?.selectTriggerElement;
		},
	},
});
</script>
<style lang="scss" scoped>
.section-layout {
	position: relative;
	margin: 0 auto; // can this be removed?
	width: 100%;
	height: 100%;
	display: grid;
	transition: height 2s ease;
}

.empty {
	outline: 2px dashed $color-neutrals-300-tint;
	border-radius: 7px;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.component {
	position: relative;

	+ .component {
		margin-top: $spacing-2xl;
	}

	@include breakpoint(mobile) {
		max-width: calc(100vw - 32px);
	}

	&:hover {
		&:not(:focus-within) {
			:deep(.component-dropzone) {
				display: block;
				opacity: 1;
			}

			:deep(.editable-property) {
				background-color: rgb(0 0 0 / 5%);
				border-bottom-color: rgb(0 0 0 / 10%);
				cursor: text;
			}

			:deep(.edit-btn),
			:deep(.delete-btn) {
				opacity: 1;
				display: block;
			}
		}

		:deep(.segmentation-label) {
			opacity: 0;
		}

		:deep(.component-id-label) {
			opacity: 0;
		}
	}
}

.full-width .component {
	@include breakpoint(mobile) {
		max-width: 100vw;
	}
}

.compact .component {
	+ .component {
		margin-top: $spacing-m;
	}
}
</style>
