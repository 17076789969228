import { _fetchData } from "~/helpers/store";
import digitalSelvhjelp from "~/integrations/digital-selvhjelp";

export const state = () => ({
	promise: null,
	data: {},
	apnResults: null,
	error: null,
	session: {},
	conclusion: "",
	updateView: false,
});

export const actions = {
	async startFlow(context, { subscriptionId }) {
		const userSubscription = await fetchSubscriptionData(context, subscriptionId);
		const response = await _fetchData(
			() => digitalSelvhjelp.startFlow(subscriptionId, userSubscription?.faultSearch?.flowId),
			{ context, force: true, name: "Digital Selvhjelp" },
		);
		context.commit("setError", response?.error || null);
		return { success: !response?.error, error: response?.error };
	},

	async advanceFlow(context, { subscriptionId, stepId, body, conclusion }) {
		const userSubscription = await fetchSubscriptionData(context, subscriptionId);
		const response = await _fetchData(
			() =>
				digitalSelvhjelp.advanceFlow(
					subscriptionId,
					userSubscription?.faultSearch?.flowId,
					stepId,
					context.state.session,
					body,
					conclusion,
				),
			{ context, force: true, name: "Digital Selvhjelp" },
		);
		context.commit("setError", response?.error || null);
		return { success: !response?.error, error: response?.error };
	},

	async activateDataPackage(context, { subscriptionId, phoneNumbers }) {
		const apnResults = await _fetchData(() => digitalSelvhjelp.activateDataPackage(subscriptionId, phoneNumbers), {
			context,
			force: true,
			name: "Activate Data Package",
		});
		context.commit("setError", apnResults?.error || null);
		context.commit("setApnResults", apnResults);
	},
};

export const mutations = {
	setData(state, data) {
		state.data = data;
		if (data?.session) {
			state.session = data.session;
		}
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setApnResults(state, apnResults) {
		state.apnResults = apnResults;
	},
	setConclusion(state, conclusion) {
		state.conclusion = conclusion;
	},
	setUpdateView(state, updateView) {
		state.updateView = updateView;
	},
};

async function fetchSubscriptionData(context, subscriptionId) {
	const cachedData = context.rootGetters?.["mittTelenor/fixed/subscriptions/getSubscriptionById"]?.(subscriptionId);
	if (cachedData) return cachedData;

	await context.dispatch("mittTelenor/fixed/subscriptions/fetchData", { subscriptionId }, { root: true });

	return context.rootGetters?.["mittTelenor/fixed/subscriptions/getSubscriptionById"]?.(subscriptionId) || null;
}

export const getters = {
	isLoading: (state) => !!state.promise && !state.error,
	hasError: (state) => !!state.error,
	currentStep: (state) => state.data,
	shouldUpdateView: (state) => state.updateView,
	statusLine: (state) => state.data.statusLine,
	apnResults: (state) => state.apnResults,
};
