import {
  changeInsuranceVuexStore,
  checkoutVuexStore,
  ecommerceVuexStore,
  ecommerceAddressVuexStore,
  hardwareServicesVuexStore,
  indexVuexStore,
  mittTelenorVuexStore,
  shoppingCartVuexStore,
  telenorbutikkenVuexStore,
  tradeinVuexStore,
  fixedOrderfixedOrderFlowVuexStore,
  fixedOrderinputVuexStore,
  fixedOrderordersVuexStore,
  fixedOrdertrackingVuexStore,
  fixedOrdervalidationsVuexStore,
  mittTelenorepostStoreVuexStore,
  safePlusdebtManagementVuexStore,
  safePlusidGuardVuexStore,
  safePlussecuritySubscriptionsVuexStore,
  mittTelenorfixeddevicesVuexStore,
  mittTelenorfixeddigitalSelvhjelpVuexStore,
  mittTelenorfixedfixedSubscriptionVuexStore,
  mittTelenorfixedfixedSubscriptionTerminationVuexStore,
  mittTelenorfixedfrihetSubscriptionVuexStore,
  mittTelenorfixednettvernPlusVuexStore,
  mittTelenorfixedordersVuexStore,
  mittTelenorfixedpremiumTvContentVuexStore,
  mittTelenorfixedpremiumTvContentSubscriptionVuexStore,
  mittTelenorfixedsubscriptionAddonOffersVuexStore,
  mittTelenorfixedsubscriptionUserOwnerPayerVuexStore,
  mittTelenorfixedsubscriptionsVuexStore,
  mittTelenorfixedtvContentVuexStore,
  mittTelenorfixedtvContentSubscriptionVuexStore,
  mittTelenorfixedtvContentThirdPartyStatusVuexStore,
  mittTelenorfixedtweBoxRemoteStoreVuexStore,
  mittTelenorfixedstbstbAuthVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      'changeInsurance': {
        ...changeInsuranceVuexStore.default ?? changeInsuranceVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'checkout': {
        ...checkoutVuexStore.default ?? checkoutVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'ecommerce': {
        ...ecommerceVuexStore.default ?? ecommerceVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'ecommerceAddress': {
        ...ecommerceAddressVuexStore.default ?? ecommerceAddressVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'hardwareServices': {
        ...hardwareServicesVuexStore.default ?? hardwareServicesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'mittTelenor': {
        ...mittTelenorVuexStore.default ?? mittTelenorVuexStore,
        namespaced: true,
        modules: {
      'epostStore': {
        ...mittTelenorepostStoreVuexStore.default ?? mittTelenorepostStoreVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'fixed': {
        
        namespaced: true,
        modules: {
      'devices': {
        ...mittTelenorfixeddevicesVuexStore.default ?? mittTelenorfixeddevicesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'digitalSelvhjelp': {
        ...mittTelenorfixeddigitalSelvhjelpVuexStore.default ?? mittTelenorfixeddigitalSelvhjelpVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'fixedSubscription': {
        ...mittTelenorfixedfixedSubscriptionVuexStore.default ?? mittTelenorfixedfixedSubscriptionVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'fixedSubscriptionTermination': {
        ...mittTelenorfixedfixedSubscriptionTerminationVuexStore.default ?? mittTelenorfixedfixedSubscriptionTerminationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'frihetSubscription': {
        ...mittTelenorfixedfrihetSubscriptionVuexStore.default ?? mittTelenorfixedfrihetSubscriptionVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'nettvernPlus': {
        ...mittTelenorfixednettvernPlusVuexStore.default ?? mittTelenorfixednettvernPlusVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'orders': {
        ...mittTelenorfixedordersVuexStore.default ?? mittTelenorfixedordersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'premiumTvContent': {
        ...mittTelenorfixedpremiumTvContentVuexStore.default ?? mittTelenorfixedpremiumTvContentVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'premiumTvContentSubscription': {
        ...mittTelenorfixedpremiumTvContentSubscriptionVuexStore.default ?? mittTelenorfixedpremiumTvContentSubscriptionVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'subscriptionAddonOffers': {
        ...mittTelenorfixedsubscriptionAddonOffersVuexStore.default ?? mittTelenorfixedsubscriptionAddonOffersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'subscriptionUserOwnerPayer': {
        ...mittTelenorfixedsubscriptionUserOwnerPayerVuexStore.default ?? mittTelenorfixedsubscriptionUserOwnerPayerVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'subscriptions': {
        ...mittTelenorfixedsubscriptionsVuexStore.default ?? mittTelenorfixedsubscriptionsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tvContent': {
        ...mittTelenorfixedtvContentVuexStore.default ?? mittTelenorfixedtvContentVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tvContentSubscription': {
        ...mittTelenorfixedtvContentSubscriptionVuexStore.default ?? mittTelenorfixedtvContentSubscriptionVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tvContentThirdPartyStatus': {
        ...mittTelenorfixedtvContentThirdPartyStatusVuexStore.default ?? mittTelenorfixedtvContentThirdPartyStatusVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tweBoxRemoteStore': {
        ...mittTelenorfixedtweBoxRemoteStoreVuexStore.default ?? mittTelenorfixedtweBoxRemoteStoreVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'stb': {
        
        namespaced: true,
        modules: {
      'stbAuth': {
        ...mittTelenorfixedstbstbAuthVuexStore.default ?? mittTelenorfixedstbstbAuthVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    },
      }
    },
      }
    ,
      'shoppingCart': {
        ...shoppingCartVuexStore.default ?? shoppingCartVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'telenorbutikken': {
        ...telenorbutikkenVuexStore.default ?? telenorbutikkenVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tradein': {
        ...tradeinVuexStore.default ?? tradeinVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'fixedOrder': {
        
        namespaced: true,
        modules: {
      'fixedOrderFlow': {
        ...fixedOrderfixedOrderFlowVuexStore.default ?? fixedOrderfixedOrderFlowVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'input': {
        ...fixedOrderinputVuexStore.default ?? fixedOrderinputVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'orders': {
        ...fixedOrderordersVuexStore.default ?? fixedOrderordersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'tracking': {
        ...fixedOrdertrackingVuexStore.default ?? fixedOrdertrackingVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'validations': {
        ...fixedOrdervalidationsVuexStore.default ?? fixedOrdervalidationsVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    ,
      'safePlus': {
        
        namespaced: true,
        modules: {
      'debtManagement': {
        ...safePlusdebtManagementVuexStore.default ?? safePlusdebtManagementVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'idGuard': {
        ...safePlusidGuardVuexStore.default ?? safePlusidGuardVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'securitySubscriptions': {
        ...safePlussecuritySubscriptionsVuexStore.default ?? safePlussecuritySubscriptionsVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    }, 
  }
export default VuexStore