export function getLayoutClasses(columns: number): string {
    switch (columns) {
        case 1:
            return "grid-columns-1 grid-row-gap-2xl";
        case 2:
            return "grid-columns-2 grid-row-gap-2xl";
        case 3:
            return "grid-columns-3 grid-row-gap-2xl";
        case 4:
        default:
            return "grid-columns-4 grid-row-gap-2xl";
    }
}

export function isCompact(id: string): boolean{
    return [
        "telenor.no-page-telenor-no-metadata-fixed-mtw-page",
        "template-telenor.no-page-telenor-no-metadata-fixed-mtw-page",
        "telenor.no-page-telenor.no-metadata-requires-login",
        "template-telenor.no-page-telenor.no-metadata-requires-login",
    ].includes(id);
}