import axiosClient from "axios";

import { setupCache } from "axios-cache-interceptor";
import telenorid from "../telenorid/telenorid";

import adapterHelper from "@/integrations/adapters/adapterHelper";

import { useUserStore } from "~/pinia/platform/user/user.ts";
import { createPinia } from "pinia";

const pinia = createPinia();

let requests = [];

let reAuthenticating;

export default function (baseURL) {
	let apiUrl = adapterHelper.apiUrl();

	const axios = axiosClient.create({
		baseURL: apiUrl + baseURL,
		withCredentials: true,
	});

	if (import.meta.client) {
		setupCache(axios, {
			ttl: 15 * 60 * 1000, // 15 minutes,
			exclude: {
				query: false,
			},
			cacheTakeover: false, // TODO: Remove when Apigee fixes cors pragma issue
		});
	}

	axios.defaults.withCredentials = true;

	const INTERVAL_MS = 10;

	// Prevent multiple parallel requests to the exact same resource
	axios.interceptors.request.use(function (conf) {
		return new Promise((resolve) => {
			let interval = setInterval(() => {
				if (!requests.includes(getKey(conf))) {
					requests.push(getKey(conf));
					clearInterval(interval);
					resolve(conf);
				}
			}, INTERVAL_MS);
		});
	});

	axios.interceptors.request.use(
		async (request) => {
			let user = await telenorid.getOrLoginUser();

			if (user) {
				request.headers.Authorization = "Bearer " + user.access_token;
			} else {
				// Cancel request`
				return {
					...request,
					cancelToken: new axiosClient.CancelToken((cancel) =>
						cancel("Cancelled request because no authentication token is present. URL: " + request.url),
					),
				};
			}

			return request;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	const getKey = (conf) => conf.baseURL + conf.url + conf.method + conf.query;

	/**
	 * Axios Response Interceptor
	 */
	axios.interceptors.response.use(
		function (response) {
			requests = requests.filter((r) => r !== getKey(response.config));
			return Promise.resolve(response);
		},
		function (error) {
			if (error.config) requests = requests.filter((r) => r !== getKey(error.config));
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		function (response) {
			return response.data;
		},
		async function (error) {
			if (error?.config?.headers) delete error.config.headers["Authorization"];

			if (error.response && error.response.status === 401) {
				try {
					/*
						If any APIs response code logic is intended to return business logic related 401s that is unrelated to the
						telenorID-user session, pass those exact response code(s) in a "escapeSessionUpdateResponseCodes" array for
						that APIs integration.

						Example: mobile-subscriptions-v1 returns 401 if the user is not authorized to get esim QR code for
						the given msisdn. It returns an additional code "40104" in the request body.
						to escape an infinite re-authentication loop, the initial request includes
						escapeSessionUpdateResponseCodes: [40104] in its config object.
					*/

					let parsedRequestBody;
					const {
						response: {
							data: { errorCode: errorCode } = undefined,
							data: { description: errorDescription } = undefined,
						},
					} = error;

					parsedRequestBody = error.response.data;

					if (typeof parsedRequestBody === "string") {
						try {
							parsedRequestBody = JSON.parse(parsedRequestBody);
						} catch (e) {
							console.error(e);
						}
					}

					if (
						parsedRequestBody?.errorCode &&
						parsedRequestBody.errorCode !== 40106 &&
						parsedRequestBody.errorCode !== "40106"
					) {
						console.log("The 401 response is unrelated to access token expiry. Returning initial response.");
						console.log(
							`Actual response: ${parsedRequestBody.errorCode}${
								parsedRequestBody.description ? ` - ${parsedRequestBody.description}` : ""
							}.`,
						);
						return Promise.reject(error);
					} else if (errorCode === 40104) {
						console.log("The 401 response is unrelated to access token expiry. Returning initial response.");
						console.log(`Actual response: ${errorCode}${errorDescription ? ` - ${errorDescription}` : ""}.`);
						return Promise.reject(error);
					}
				} catch (e) {
					console.error(e);
				}

				// This will happen if the user is logged in to Telenor ID+, but the Apigee token is expired
				console.log("Access token is expired");

				await telenorid.properlyRemoveUser();

				const shouldAttemptReauthentication = reAuthenticating?.then || reAuthenticating === undefined; // Ensure that we only try once

				if (shouldAttemptReauthentication) {
					const userStore = useUserStore(pinia);

					console.log("Attempting reauthentication...");
					if (!reAuthenticating) {
						console.log("No existing reauthentication promise. Creating new one.");
						reAuthenticating = telenorid.signinSilent();
					}
					try {
						reAuthenticating = await reAuthenticating;
						console.log("Finished reauthentication");
					} catch (error) {
						console.error(error);
						console.warn("Reauthenticated failed, clearing customer, login required");
						userStore.clearCustomer();
					} finally {
						userStore.attemptedLogin = true;
					}

					if (reAuthenticating?.profile) {
						// Successfully reauthenticated the user
						console.log("successfully reauthenticated user", reAuthenticating);
						userStore.setUser(JSON.parse(JSON.stringify(reAuthenticating)));
						reAuthenticating = undefined;

						return axios(error.config);
					} else {
						userStore.clearCustomer();
						return Promise.reject(error);
					}
				} else {
					return Promise.reject(error);
				}
			}
			if (error.response && error.response.status === 403) {
				return Promise.reject(error);
			}

			if (error?.constructor?.name === "AxiosError") {
				return Promise.reject({
					message: error.message,
					code: error.code,
					response: {
						status: error.response?.status,
						data: error.response?.data,
					},
					request: {
						url: error.config?.url,
						method: error.config?.method,
						data: error.config?.data,
					},
				});
			}

			return Promise.reject(error);
		},
	);

	return axios;
}
