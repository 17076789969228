/**
 * Axios adapter for the Hey API client with caching, concurrency, and re-authentication support.
 */
import axios from "axios";
import type { AxiosError } from "axios";
import type { ClientOptions } from "@hey-api/client-axios";
import { createClient, createConfig } from "@hey-api/client-axios";
import telenorid from "~/telenorid/telenorid";
import { setupCache, buildMemoryStorage } from "axios-cache-interceptor";
import adapterHelper from "~/integrations/adapters/adapterHelper";
import { getCacheKey } from "~/helpers/cacheKey";
import { useUserStore } from "~/pinia/platform/user/user";
import type { IUserAuth } from "~/pinia/platform/user/user";

const apiUrl = adapterHelper.apiUrl();
const cacheStorage = buildMemoryStorage();

// Create the Hey API client with Axios configuration
export const client = createClient(
	createConfig<ClientOptions>({
		baseURL: apiUrl,
		throwOnError: true,
	}),
);

// How long responses stay fresh in cache (15 minutes)
const TTL = 15 * 60 * 1000;
// Polling interval (in ms) to check if a concurrent request for the same resource has finished
const INTERVAL_MS = 100;

/**
 * Return fresh and stale durations for caching, irrespective of Cache-Control.
 * This ensures the response is cached for TTL and won't have a stale period.
 */
function headerInterpreter(_headers: Record<string, unknown>): { cache: number; stale: number } {
	return { cache: TTL, stale: 0 };
}

// Only run the caching logic in the browser environment
if (import.meta.client) {
	setupCache(client.instance, {
		ttl: TTL,
		cacheTakeover: false, // TODO: Remove when Apigee fixes CORS pragma issue
		storage: cacheStorage,
		headerInterpreter,
		generateKey: (config) => getCacheKey(config),
	});
}

// Track ongoing requests to avoid multiple parallel requests to the same resource
let requestsInFlight: string[] = [];

/**
 * An interceptor that prevents multiple parallel requests to the same resource.
 * If a request is already in flight, subsequent requests wait until it completes.
 */
client.instance.interceptors.request.use(async (config) => {
	const cacheKey = getCacheKey(config);

	// If an item is already cached, skip concurrency check and proceed
	const cacheItem = await cacheStorage.get(cacheKey);
	if (cacheItem.state === "cached") {
		return config;
	}

	// Concurrency check
	return new Promise((resolve) => {
		const interval = setInterval(() => {
			if (!requestsInFlight.includes(cacheKey)) {
				requestsInFlight.push(cacheKey);
				clearInterval(interval);
				resolve(config);
			}
		}, INTERVAL_MS);
	});
});

/**
 * An interceptor that attaches the bearer token to every request if available.
 * Otherwise, it cancels the request.
 */
client.instance.interceptors.request.use(
	async (request) => {
		const user = await telenorid.getOrLoginUser();
		if (user) {
			request.headers.set("Authorization", `Bearer ${user.access_token}`);
			return request;
		}
		return {
			...request,
			cancelToken: new axios.CancelToken((cancel) => {
				cancel(`Cancelled request because no authentication token is present. URL: ${request.url}`);
			}),
		};
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	},
);

/**
 * Clean up tracked requests on response completion or error.
 */
client.instance.interceptors.response.use(
	(response) => {
		requestsInFlight = requestsInFlight.filter((req) => req !== getCacheKey(response.config));
		return Promise.resolve(response);
	},
	(error: AxiosError) => {
		if (error.config) {
			requestsInFlight = requestsInFlight.filter((req) => req !== getCacheKey(error.config));
		}
		return Promise.reject(error);
	},
);

/**
 * Handle 401 errors by attempting silent re-authentication.
 * If successful, retries the original request; otherwise rejects the request.
 */

let reauthPromise: Promise<IUserAuth> | null = null;
client.instance.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		// Return early if not a 401 or missing config
		if (error.response?.status !== 401 || !error.config?.headers) {
			return Promise.reject(error);
		}

		// If we've already retried once, just return early
		if ((error.config as any).__isRetry) {
			return Promise.reject(error);
		}

		// Return early if 401 isn't related to token expiry
		if (isNot401TokenExpiredError(error)) {
			return Promise.reject(error);
		}

		// Mark this request as "retry in progress" so if 401 again, we skip
		(error.config as any).__isRetry = true;

		// Remove expired auth header
		delete error.config.headers.Authorization;

		// Assume the token is expired
		console.log("Access token is expired");
		await telenorid.properlyRemoveUser();

		// If no reauth is in progress, start one
		if (!reauthPromise) {
			console.log("Creating reauthentication promise");
			const userStore = useUserStore();
			reauthPromise = telenorid
				.signinSilent()
				.then((user: IUserAuth) => {
					console.log("Reauthentication successful");
					if (user.access_token) {
						console.log("Setting user data");
						userStore.setUser(JSON.parse(JSON.stringify(user)));
					}
				})
				.catch((reauthError: Error) => {
					userStore.clearCustomer();
					throw reauthError; // re-throw so that await reauthPromise rejects
				})
				.finally(() => {
					reauthPromise = null;
					userStore.attemptedLogin = true;
				});
		}

		// Wait on (new or existing) reauthPromise
		try {
			await reauthPromise;
			// Resend the request when the user is reauthed, the attach-bearer interceptor will set the new token
			return client.instance.request(error.config);
		} catch (reauthError) {
			console.error("Reauthentication failed", reauthError, "Clearing user data, login required");
			return Promise.reject(error);
		}
	},
);

/**
 * Checks if the given 401 error is unrelated to an expired access token.
 */
function isNot401TokenExpiredError(error: AxiosError): boolean {
	const { data: errorData } = error.response!;
	let parsedData: any = errorData;

	try {
		if (typeof errorData === "string") {
			parsedData = JSON.parse(errorData);
		}
	} catch (parseError) {
		console.error("Unable to parse error response:", parseError);
	}

	const errorCode = parsedData?.errorCode ?? null;
	const errorDescription = parsedData?.description ?? null;

	const notTokenExpiry = errorCode && errorCode !== 40106 && errorCode !== "40106";

	if (notTokenExpiry) {
		console.log(
			`The 401 response is unrelated to access token expiry. errorCode: ${errorCode}${
				errorDescription ? ` - ${errorDescription}` : ""
			}`,
		);
		return true;
	}

	return false;
}
