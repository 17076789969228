import { LoginMethod, TnAnalytics } from "@telenornorgeinternal/tealium-analytics";
import type { Product } from "@telenornorgeinternal/tealium-analytics";

type ProductOrProductPromise = Product | Promise<Product>;

interface AnalyticsComposable extends TnAnalytics {
	addProduct(): void;

	resetProducts(): void;

	hasProducts(): boolean;

	getProducts(): ProductOrProductPromise[];
}

const tnAnalytics = ref(null);

const products = ref<ProductOrProductPromise[]>([]);

function addProduct(product: ProductOrProductPromise) {
	products.value.push(product);
}

function resetProducts() {
	products.value = [];
}

function hasProducts() {
	return products.value.length > 0;
}

function getProducts() {
	return products.value;
}

export function useAnalytics(): AnalyticsComposable | null {
	const { tealium_profile, tealium_env = "qa" } = useRuntimeConfig().public;
	const userStore = useUserStore();
	const contactDetailsStore = useContactDetailsStore();

	const init = () => {
		tnAnalytics.value = new TnAnalytics({
			tealium: {
				profile: tealium_profile,
				env: tealium_env,
				singlePageApp: true,
			},
			user: (async () => {
				await userStore.getOrSetUser();
				if (userStore.customer) {
					await contactDetailsStore.fetchContactDetails();
					return {
						kurt: userStore.customer.kurtId,
						birthDate: userStore.customer.birthDate,
						email: contactDetailsStore.emailAddress,
						phone: contactDetailsStore.phoneNumber,
						loginMethod: LoginMethod.fromAcr(userStore.customer.acr),
					};
				} else {
					return null;
				}
			})(),
		});
	};

	if (!tnAnalytics.value) {
		if (import.meta.client) init();
		else tnAnalytics.value = {};
		tnAnalytics.value.addProduct = addProduct;
		tnAnalytics.value.resetProducts = resetProducts;
		tnAnalytics.value.hasProducts = hasProducts;
		tnAnalytics.value.getProducts = getProducts;
	}

	return tnAnalytics.value; /*{
		...tnAnalytics.value,
		addProduct,
		resetProducts,
		products
	};*/
}
