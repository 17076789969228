
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as project_45stategvVFtUXX0Lf8N7mTSxtiDncS9DsgOVLhkym0T6Mra6cMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/modules/project-analysis/runtime/pages/project-state.vue?macro=true";
import { default as _91_46_46_46url_93VfGU_45nW80KpsMA9C5o0ypD9cvnk9fJrmCzpcr9SxEkkMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/[...url].vue?macro=true";
import { default as microsoftwkazEc1j2a_45YyorEMFYQpwQ_45bGHnpCGM3mbnaxW4VdoMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/esim/microsoft.vue?macro=true";
import { default as indexiJAnvYASRfXa89Ibp1lArAnTQPs_4Qnp2MNu4OATtDcMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/index.vue?macro=true";
import { default as loginSJlDutxDHAADhI505kDnjKuRgi5_45t_45S_45RYZq_45qWwnJUMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/login.vue?macro=true";
import { default as loginCallbackZ88is8Hnik7VPiczU2nUuoJvNsff63hYhIFeOiGxVnEMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/loginCallback.vue?macro=true";
import { default as loginCallbackTweBoxDI2zKMrLKSTL1eb1vPFFs6N7y_45apVLtKNuIf5OuJE1MMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/loginCallbackTweBox.vue?macro=true";
import { default as logoutIawPtqR_45Ug1pHm6Nsioj2j8PC4NVmMRb4HW7wwmZwj8Meta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/logout.vue?macro=true";
import { default as logoutCallbackSdjt8oMO5nJEhBmFgAKIcavVpr9NiWhkucXkEC8LjOYMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/logoutCallback.vue?macro=true";
import { default as index0dmpo46KJ5TjpU73IH8Naw7Gs1hfRHyTvU8KkdyEQeAMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/sok/index.vue?macro=true";
import { default as sokPageName6k6XYXDIl96EnwqZII5GJDR2au24MnjzCo8IHpojk_0Meta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/sok/sokPageName.ts?macro=true";
import { default as oppsummeringU_45cgc2eguwTLR38rpXz_HVXNy52IPaULeC2C7JCWRBEMeta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/tv/bestill/oppsummering.vue?macro=true";
import { default as produkterQlpKTOkI_sG6Un3xxGjJf6iRRwnic4dtmOyJiGHFd_0Meta } from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/tv/bestill/produkter.vue?macro=true";
export default [
  {
    name: "project-state",
    path: "/project-state",
    meta: project_45stategvVFtUXX0Lf8N7mTSxtiDncS9DsgOVLhkym0T6Mra6cMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/modules/project-analysis/runtime/pages/project-state.vue")
  },
  {
    name: "CMS-managed page",
    path: "/:url(.*)*",
    meta: _91_46_46_46url_93VfGU_45nW80KpsMA9C5o0ypD9cvnk9fJrmCzpcr9SxEkkMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/[...url].vue")
  },
  {
    name: "ESIM Microsoft",
    path: "/esim/microsoft",
    meta: microsoftwkazEc1j2a_45YyorEMFYQpwQ_45bGHnpCGM3mbnaxW4VdoMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/esim/microsoft.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiJAnvYASRfXa89Ibp1lArAnTQPs_4Qnp2MNu4OATtDcMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginSJlDutxDHAADhI505kDnjKuRgi5_45t_45S_45RYZq_45qWwnJUMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/login.vue")
  },
  {
    name: "loginCallback",
    path: "/loginCallback",
    meta: loginCallbackZ88is8Hnik7VPiczU2nUuoJvNsff63hYhIFeOiGxVnEMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/loginCallback.vue")
  },
  {
    name: "loginCallbackTweBox",
    path: "/loginCallbackTweBox",
    meta: loginCallbackTweBoxDI2zKMrLKSTL1eb1vPFFs6N7y_45apVLtKNuIf5OuJE1MMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/loginCallbackTweBox.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutIawPtqR_45Ug1pHm6Nsioj2j8PC4NVmMRb4HW7wwmZwj8Meta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/logout.vue")
  },
  {
    name: "logoutCallback",
    path: "/logoutCallback",
    meta: logoutCallbackSdjt8oMO5nJEhBmFgAKIcavVpr9NiWhkucXkEC8LjOYMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/logoutCallback.vue")
  },
  {
    name: "sok",
    path: "/sok",
    meta: index0dmpo46KJ5TjpU73IH8Naw7Gs1hfRHyTvU8KkdyEQeAMeta || {},
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/sok/index.vue")
  },
  {
    name: "sok-sokPageName",
    path: "/sok/sokPageName",
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/sok/sokPageName.ts")
  },
  {
    name: "tv-bestill-oppsummering",
    path: "/tv/bestill/oppsummering",
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/tv/bestill/oppsummering.vue")
  },
  {
    name: "tv-bestill-produkter",
    path: "/tv/bestill/produkter",
    component: () => import("/home/runner/work/s02679-telenor-no/s02679-telenor-no/pages/tv/bestill/produkter.vue")
  }
]