export const state = () => ({
	trackingType: "order",
});
export const actions = {};
export const mutations = {
	setTrackingType(state, trackingType) {
		state.trackingType = trackingType;
	},
};
export const getters = {
	validationStarted() {
		return {
			event_name: "Validation Start",
			events: { event42: 1 },
		};
	},
	validationFailed() {
		return {
			event_name: "Validation Failed",
			events: { event42: 1 },
		};
	},
	orderProcessStart() {
		return {
			event_name: "Order Process Start",
			events: { event42: 1 },
		};
	},
	orderProcessFailed() {
		return {
			event_name: "Order Process Failed",
			events: { event20: 1 },
		};
	},
	trackingDataAvailableTech(state, getters, rootState, rootGetters) {
		return {
			evar6_available_tech: [
				...(rootGetters["fixedOrder/input/offersArePlanned"] ? ["PLANNED"] : []),
				...(rootGetters["fixedOrder/input/offersAreCabin"] ? ["CABIN"] : []),
				...rootGetters["fixedOrder/input/valueChains"],
				...rootGetters["fixedOrder/input/technologies"],
			]?.join(" "),
		};
	},

	trackingDataProductString(state, getters, rootState, rootGetters) {
		return {
			event_name: "purchase",
			sc_events: { purchase: 1 },
			...(rootGetters["fixedOrder"]?.orders?.order?.orderId
				? { order_id: rootGetters["fixedOrder"]?.orders?.orderId }
				: {}),
			...(getters?.productString?.length ? { products: getters.productString } : {}),
		};
	},

	trackingTermination(state, getters, rootState, rootGetters) {
		return {
			event_name: "termination",
			sc_events: { event271: 1 },
			...(rootGetters["fixedOrder"]?.orders?.order?.orderId
				? { order_id: rootGetters["fixedOrder"]?.orders?.orderId }
				: {}),
			...(getters?.productString?.length ? { products: getters.productString } : {}),
		};
	},

	trackingDataServiceChange(state, getters) {
		return {
			sc_events: { purchase: 1, event7: 1 },
			eVar15_serviceChange: `${getters.serviceChange?.before} => ${getters?.serviceChange.after}`,
		};
	},

	// String format: ";[name(id)];[amount];[price],;[name(id)];[amount];[price]"
	productString(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/validations/validatedOrder"]?.orderInformation?.offers
			?.reduce(
				(acc, curr) =>
					Array.from(
						new Set(
							curr.products?.map((p) =>
								Object.values({
									nameAndId: `;${p.productName?.replace(/[,;]/g, "")}(${p.productId})`,
									amount: curr.products.filter((x) => x.productId === p.productId).length,
									price: p.prices?.ONE_TIME?.amount || p.prices?.MONTHLY?.amount || 0,
								})?.join(";"),
							),
						),
					),
				[],
			)
			.join(",");
	},

	serviceChange(state, getters) {
		const noChangeProducts = getters.noChangeProducts || [];
		const removedProducts = getters.removedProducts || [];
		return {
			before: [...noChangeProducts, ...removedProducts]?.map((product) => product?.productName)?.join(" og "),
			after: [...noChangeProducts, ...removedProducts]?.map((product) => product?.productName)?.join(" og "),
		};
	},

	newProducts(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/validations/validatedOrder"]?.orderInformation?.offers?.[0]?.products?.filter(
			(product) => product?.isMainProduct && product?.action === "NEW",
		);
	},

	removedProducts(state, getters, rootState, rootGetters) {
		let validatedRemoved = rootGetters[
			"fixedOrder/validations/validatedOrder"
		]?.orderInformation?.offers?.[0]?.products?.filter(
			(product) => product?.isMainProduct && product?.action === "REMOVED",
		);
		// This block is only here because there are different handling between value chains
		// Since the current subscription cannot be selected, we assume that it has been removed
		// and replaced with the product received from product validation with {{ action::'NEW' }}
		if (
			rootGetters?.["fixedOrder/fixedOrderFlow"]?.currentFlow?.name === "change-bandwidth" &&
			rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology !== "FWA" &&
			!validatedRemoved?.length
		) {
			let removedMainProduct = rootGetters["mittTelenor/fixed/fixedSubscription/subscribedMainProduct"];
			console.log("removed main", removedMainProduct);
			return [{ ...removedMainProduct, productName: removedMainProduct?.names?.text }];
		} else {
			return validatedRemoved;
		}
	},

	noChangeProducts(state, getters, rootState, rootGetters) {
		let validatedNoChange = rootGetters[
			"fixedOrder/validations/validatedOrder"
		]?.orderInformation?.offers?.[0]?.products?.filter(
			(product) => product?.isMainProduct && product?.action === "NO_CHANGE",
		);
		// This block is only here because there are different handling between value chains
		// Since the current subscription cannot be selected, we assume that it has not changed
		// and that a new main product (TV) has been added.
		if (
			rootGetters?.["fixedOrder/fixedOrderFlow"]?.currentFlow?.name === "add-tv" &&
			rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology !== "FWA" &&
			!validatedNoChange?.length
		) {
			let noChangeMainProduct = rootGetters["mittTelenor/fixed/fixedSubscription/subscribedMainProduct"];
			return [{ ...noChangeMainProduct, productName: noChangeMainProduct?.names?.text }];
		} else {
			return validatedNoChange;
		}
	},

	orderRunEvent(state, getters, rootState, rootGetters) {
		return {
			tealium_event: "link",
			link_name: "FTV Order Run Events",
			ftv_order_run_step: rootState["fixedOrder"]["fixedOrderFlow"].currentStep,
			ftv_order_run_context: rootGetters["fixedOrder/fixedOrderFlow/currentFlow"]?.name,
			ftv_order_run_technology: rootGetters["fixedOrder/input/selectedBundle"],
			from: "tracking.js:orderRunEvent",
		};
	},

	trackingType: (state) => state.trackingType,
};
