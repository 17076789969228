import abTestTrack from "@telenornorgeinternal/tn-gizmo-abtest-plugin";
import { usePageStore } from "~/pinia/platform/page.ts";
import jsonpointer from "jsonpointer";
import { useGizmoStore } from "~/pinia/platform/gizmo.ts";

export default defineNuxtPlugin((nuxtApp) => {
	const pageStore = usePageStore();
	const gizmoStore = useGizmoStore();

	if (nuxtApp.$config.public.previewMode) {
		if (gizmoStore.selectTriggerElement) {
			abTestTrack.initGobalSelectTriggerElement(nuxtApp.$config.domain, gizmoStore.testType);

			nuxtApp.app.router.beforeEach(() => {
				return false; // prevent navigation
			});
		}

		window.onmessage = (message) => {
			if (message.data) {
				let { type, change } = message.data;

				if (change?.dataPath) change.dataPath = change.dataPath.replace(/^\/abTestPages\/\d/, "");

				if (type && type === "change") {
					//store.commit("handleChange", change);
					console.log("change", change);
					jsonpointer.set(pageStore.page, change.dataPath, change.value);
					console.log("pagestore page", pageStore.page);
				} else if (type && type === "delete") {
					jsonpointer.set(pageStore.page, change.dataPath, undefined);
				} else if (type && type === "draggingLayout") {
					gizmoStore.dragType = "layout";
				} else if (type && type === "draggingComponent") {
					gizmoStore.dragType = "component";
				} else if (type && type === "dragEnd") {
					gizmoStore.dragType = "";
				} else if (type && type === "goto") {
					let dataPath = `/layouts/${change.layout}/columns/${change.column}/${change.component}`;
					if (change.abtest !== undefined) dataPath = `/abTestPages/${change.abtest}${dataPath}`;
					let el = document.querySelector(`[data-path="${dataPath}"]`);
					el.scrollIntoView();
				} else if (type && type === "reload") {
					window.location.reload();
				} else if (type && type === "segments") {
					gizmoStore.setSegments(change);
				} else if (type && type === "labels") {
					gizmoStore.labels = change;
				} else if (type && type === "showAllSegmentedContent") {
					gizmoStore.showAllSegmentedContent = change;
				} else if (type && type === "simulateLoggedIn") {
					gizmoStore.loggedIn = change;
				} else if (type && type === "device") {
					gizmoStore.spoofUserAgent(change);
				} else if (type && type === "getLinkCallback") {
					console.log("getLinkCallback");
					gizmoStore.handleLinkCallback(change);
				} else if (type && type === "getImageCallback") {
					gizmoStore.handleImageCallback(change);
				}
			}
		};
	}
});
