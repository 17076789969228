<template>
	<Component
		:is="isForm ? 'form' : 'section'"
		v-if="segmentedLayout.length > 0 || $config.public.previewMode"
		:id="'l-' + index"
		:class="[...sectionWidth, sectionClasses, { editable, dark }]"
		:color="color"
		class="container-fluids"
		v-bind="formWrapperAttributes"
		@submit="handleSubmit"
		@update:modelValue.once="trackStart"
	>
		<!-- TODO some issues with hero banners -->
		<div
			:data-path="dataPath"
			class="content-container"
		>
			<ClientOnly v-if="$config.public.previewMode">
				<LazyGizmoLayoutProperties
					v-if="editable"
					:idx="index"
					:layout="layout"
				/>
				<LazySegmentationLabel
					v-if="mustSegments.length"
					:dark="dark"
					:segments="mustSegments"
					color="green"
				/>
				<LazySegmentationLabel
					v-if="mustNotSegments.length"
					:dark="dark"
					:segments="mustNotSegments"
					color="red"
				/>
			</ClientOnly>
			<ClientOnly>
				<input
					type="hidden"
					name="token"
					:value="customer.access_token"
					v-if="isFormWithEndUserAuth && customer"
				/>
			</ClientOnly>
			<TLayout
				:data-path="dataPath"
				:dataPath="dataPath"
				:index="index"
				:layout="layout"
				:segmentedLayout="segmentedLayout"
			>
				<template #default="slotProps">
					<TComponent
						:col-idx="slotProps.colIndex"
						:comp-idx="slotProps.compIndex"
						:component="slotProps.component"
						:dark="dark"
						:hasBackground="hasBackground"
						:last="slotProps.isLast"
						:layout-idx="index"
						:pagemeta="page.metadata"
						:theme="theme"
						:toyt="layout.toyt"
						v-if="slotProps.component"
					/>
				</template>
			</TLayout>
		</div>
	</Component>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "pinia";
import { usePageStore } from "~/pinia/platform/page.ts";
import { useUserStore } from "~/pinia/platform/user/user.js";
import filterComponent from "~/helpers/segmentation/filterComponent.js";
import hasSegmentation from "~/helpers/segmentation/hasSegmentation.js";
import { useFormStore } from "~/pinia/platform/form/form.js";

const identity = (x) => x;

const COMPONENT_WIDTH_BLACKLIST = [
	"gatewayLinksBar",
	"heroBannerVideo",
	"heroBannerNormal",
	"heroBannerCarousel",
	"heroBanner",
].map((str) => str.toLowerCase());
const COMPONENT_ARTICLE_WIDTH_WHITELIST = [
	"banner5050",
	"gridProductsFilter",
	"listCards",
	"gizmoSubscriptionCardsV2",
].map((str) => str.toLowerCase());

const PAGE_TEMPLATE_WIDTH_OVERRIDES = {
	"template-telenor.no-page-telenor-no-metadata-twe-box-web-app-page": "full-width",
	"telenor.no-page-telenor-no-metadata-twe-box-web-app-page": "full-width",
};

// Locals
const NO_SPACING_COMPONENTS = [
	"hero-banner--normal",
	"hero-banner--video",
	"hero-banner-carousel",
	"gateway-links-bar",
	"hero-banner--video-twe",
	"vev-component-no-spacing",
];

const SPACING = {
	big: (placement) => `section-spacing-${placement}-big`,
	default: (placement) => `section-spacing-${placement}`,
};

export default defineNuxtComponent({
	name: "TSection",
	props: ["layout", "index", "isTweWebApp", "pageColor", "last"],

	data() {
		return {
			is7030: this.layout.width === "70%/30%",
			submitted: false,
		};
	},

	setup(props) {
		let color = props.layout.background;
		if (props.isTweWebApp) {
			color = "twe-web-app";
		}

		const backgroundColor = useBackgroundColor(props.layout?.background);

		const { mustSegments, mustNotSegments } = useSegmentLabels(null, props.layout);

		return {
			color,
			backgroundColor,
			mustSegments,
			mustNotSegments,
		};
	},

	computed: {
		...mapGetters(["segments"]),
		...mapState(useUserStore, ["customer"]),
		...mapState(usePageStore, ["page"]),
		...mapState(useFormStore, ["form"]),
		metadata() {
			return this.layout?.metadata;
		},
		isForm() {
			return this.metadata && (this.metadata.formEmail || this.metadata.apiEndpoint);
		},
		formWrapperAttributes() {
			if (this.isForm) {
				return {
					method: "POST",
					enctype: "multipart/form-data",
					action: `/api/form/${this.page.id}`,
					name: "formwrapper",
				};
			} else {
				return {};
			}
		},
		segments() {
			return this.customer?.segments || [];
		},
		dataPath() {
			if (!isNaN(parseInt(this.$store.state.testIndex)))
				return "/abTestPages/" + this.$store.state.testIndex + "/layouts/" + this.index;
			return "/layouts/" + this.index;
		},
		spacing() {
			const getBackgroundColor = (index) => {
				if (index < 0) return this.pageColor;
				const background = this.page.layouts[index]?.background;
				return [!background, background === "transparent"].some((e) => e) ? this.pageColor : background;
			};

			const verticalSpacing = { top: "", bottom: "" };
			const isSingleColumnSection = this.layout.columns.length === 1;

			const differentColorAbove = getBackgroundColor(this.index - 1) !== getBackgroundColor(this.index);
			const differentColorBelow = getBackgroundColor(this.index + 1) !== getBackgroundColor(this.index);

			// Normal Spacing Rules
			if (this.index === 0) {
				verticalSpacing.top = SPACING.default("top");
				verticalSpacing.bottom = differentColorBelow ? SPACING.big("bottom") : SPACING.default("bottom");
			} else if (this.index === this.page.layouts.length - 1) {
				verticalSpacing.top = differentColorAbove ? SPACING.big("top") : SPACING.default("top");

				verticalSpacing.bottom = SPACING.default("bottom");
			} else {
				verticalSpacing.top = differentColorAbove ? SPACING.big("top") : SPACING.default("top");

				verticalSpacing.bottom = differentColorBelow ? SPACING.big("bottom") : SPACING.default("bottom");
			}

			// Special Cases Where Top/Bottom Spacing is removed completely
			if (isSingleColumnSection) {
				const firstComponentInSection = this.layout.columns?.[0][0]?.template?.name;
				const lastComponentInSection = this.layout.columns?.[0][this.layout.columns[0].length - 1]?.template?.name;

				if (NO_SPACING_COMPONENTS.includes(firstComponentInSection)) {
					verticalSpacing.top = "";
				}
				if (NO_SPACING_COMPONENTS.includes(lastComponentInSection)) {
					verticalSpacing.bottom = "";
				}
			}
			return verticalSpacing;
		},
		compact() {
			return isCompact(this.page.template?.id);
		},
		hasBackground() {
			return this.color && this.color !== "white" && this.color !== "transparent";
		},
		sectionClasses() {
			const classes = [];

			// For now, since page color is white by default, transparent means white.
			if (this.color && this.color !== "white" && this.color !== "transparent") {
				classes.push("has-bg");
				if (this.pageColor !== "dark") classes.push(this.backgroundColor);
			}

			if (this.pageColor === "dark") return [...classes, this.spacingClasses, this.backgroundColor];
			return [...classes, this.spacingClasses];
		},
		// part of sectionspacing
		spacingClasses() {
			return [
				this.spacing.top,
				this.spacing.bottom,
				...(this.compact ? ["compact"] : []),
				...(this.$store.state.showSpacing ? ["showSpacing"] : []),
			];
		},
		isFormWithEndUserAuth() {
			if (!this.isForm) return false;
			if (this.metadata.apiEndpoint?.startsWith("http")) {
				const url = new URL(this.metadata.apiEndpoint);
				return url.hostname.endsWith("api.telenor.no");
			}
		},
		sectionWidth() {
			const classes = [];
			const hasComponentInArticleWhitelist = this.layout?.columns?.some((column) => {
				return column.some((component) => {
					return COMPONENT_ARTICLE_WIDTH_WHITELIST.includes(component.template?.ref?.toLowerCase());
				});
			});
			if (this.page.template?.id in PAGE_TEMPLATE_WIDTH_OVERRIDES) {
				classes.push(PAGE_TEMPLATE_WIDTH_OVERRIDES[this.page.template?.id]);
			} else if (hasComponentInArticleWhitelist) {
				const width = this.layout.width;
				if (this.is7030) classes.push("layout-width-100");
				const widthClass = width && `layout-width-${width.replace("%", "")}`;
				classes.push(widthClass);
				return classes;
			} else if (this.page.metadata?.sectionConfig?.width) {
				classes.push(this.page.metadata.sectionConfig.width);
			} else if (this.layout.columns.length === 1) {
				const templateRef = this.layout.columns[0][0]?.template?.ref;
				const firstComponentInSection = templateRef?.split("/").pop();
				const firstComponentNameInSection = this.layout.columns[0][0]?.template?.name;

				if (COMPONENT_WIDTH_BLACKLIST.includes(firstComponentInSection.toLowerCase())) {
					classes.push("full-width");
				} else if (["vev-component-no-spacing"].includes(firstComponentNameInSection)) {
					classes.push("full-width");
				}
			}
			const width = this.layout.width;
			if (this.is7030) classes.push("layout-width-100");
			const widthClass = width && `layout-width-${width.replace("%", "")}`;
			classes.push(widthClass);
			return classes;
		},
		theme() {
			return this.color && this.color === "white"
				? "white"
				: this.color && this.color === "twe-web-app"
					? "twe-web-app"
					: this.color && this.color === "dark"
						? "dark"
						: "color";
		},
		showAllSegmentedContent() {
			return (
				this.$config.public.previewMode &&
				[useGizmoStore()?.showAllSegmentedContent, useGizmoStore()?.dragType === "component"].some(identity)
			);
		},
		segmentedLayout() {
			if (this.$config.public.previewMode) return this.layout.columns;
			return this.layout.columns
				.map((column) =>
					column.filter(identity).filter((comp) => {
						if (!comp) return false;
						if (this.$store.getters["segmentationDisabled"] || !hasSegmentation(comp)) return true;

						return filterComponent(comp, this.$store.state);
					}),
				)
				.filter((column) => column.length > 0);
		},
		dark() {
			return this.layout.background && (this.layout.background === "dark" || this.layout.background === "black");
		},
		editable() {
			return this.$config.public.previewMode && !this.$store.state.selectTriggerElement;
		},
	},

	methods: {
		trackStart() {
			try {
				useAnalytics().custom.event({
					details: {
						formStart: "1",
					},
				});
			} catch (err) {
				console.error("Error tracking formStart", err.message);
			}
		},
		async handleSubmit(e) {
			e.preventDefault();
			const invalidField = this.invalidField();
			if (invalidField) {
				const invalidElement = document.querySelector(`.component[id='${invalidField}']`);
				if (invalidElement) {
					invalidElement.focus();
					invalidElement.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			} else {
				const data = new FormData(e.target);

				try {
					this.form.submitting = true;
					const newPageState = await $fetch(`/api/form/${this.page.id}`, {
						method: "POST",
						body: data,
					});

					usePageStore().updateLayoutAtIndex(this.index, newPageState);
					useFormStore().setFormSubmitted();
					window.scrollTo(0, 0);

					//trackEnd
					try {
						useAnalytics().custom.event({
							details: {
								formEnd: "1",
							},
						});
					} catch (err) {
						console.error("Error tracking formEnd", err.message);
					}
				} catch (err) {
					console.error("Error submitting form", err.message);
				} finally {
					this.form.submitting = false;

					await this.$forceUpdate();

					const errors = document.querySelectorAll(".error");

					if (errors.item(0)) errors.item(0).scrollIntoView();

					const serverError = document.getElementById("form-submission-error");

					if (serverError) serverError.scrollIntoView();
				}
			}
		},
		invalidField() {
			const requiredFields = this.form.requiredFields;
			requiredFields.forEach((field) => {
				if (typeof field.validate === "function") field.validate();
			});

			return requiredFields.length > 0 ? requiredFields[0].elementId : null;
		},
	},

	mounted() {
		const formStore = useFormStore();
		if (this.isForm) {
			formStore.resetRequiredFormFields();
			formStore.resetTotalFileSize();
		}
	},
});
</script>

<!-- Add this to get a scope on .content-container that's used on the InlineEditableLayoutMixin.js -->
<style lang="scss" scoped>
.section-spacing-top {
	padding-top: $spacing-4xl;

	&.compact {
		padding-top: $spacing-2xl;
	}

	&-big {
		padding-top: $spacing-5xl;

		&.compact {
			padding-top: $spacing-3xl;

			:deep(.gizmo-layout-properties) {
				top: -$spacing-2xl;
				bottom: unset;
			}

			:deep(.component-dropzone) {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		:deep(.gizmo-layout-properties) {
			top: -$spacing-2xl;
			bottom: unset;
		}

		:deep(.component-dropzone) {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	:deep(.gizmo-layout-properties) {
		top: -$spacing-2xl;
		bottom: unset;
	}

	:deep(.component-dropzone) {
		left: 50%;
		transform: translateX(-50%);
	}
}

.section-spacing-bottom {
	padding-bottom: $spacing-4xl;

	:deep(.component-dropzone) {
		&.below {
			bottom: 7px;
		}
	}

	&.compact {
		padding-bottom: $spacing-2xl;
	}

	&-big {
		padding-bottom: $spacing-5xl;

		&.compact {
			padding-bottom: $spacing-3xl;
		}
	}
}

@include breakpoint(mobile) {
	.section-spacing-top {
		padding-top: $spacing-3xl;

		&.compact {
			padding-top: $spacing-xl;
		}

		&-big {
			padding-top: $spacing-4xl;

			&.compact {
				padding-top: $spacing-2xl;
			}
		}
	}

	.section-spacing-bottom {
		padding-bottom: $spacing-3xl;

		&.compact {
			padding-bottom: $spacing-xl;
		}

		&-big {
			padding-bottom: $spacing-4xl;

			&.compact {
				padding-bottom: $spacing-2xl;
			}
		}
	}
}
</style>

<!-- This can't be scoped since Adobe Target breaks/removes the data-v id -->
<style lang="scss">
.spinner-wrapper {
	display: block;
	width: 100%;
	text-align: center;

	:deep(.spinner) {
		width: 20px;
	}
}

.editable {
	position: relative;

	.content-container {
		position: relative;
	}

	&:hover {
		.gizmo-layout-properties {
			opacity: 1;
		}

		.delete-btn.delete-layout {
			opacity: 1;
		}
	}

	// check the ecommerce product page
	.column-wrapper {
		border-left: 3px double transparent;
		border-right: 3px double transparent;
		margin-left: auto;
		margin-right: auto;
		transition:
			border-left-color 0.2s ease-in-out,
			border-right-color 0.2s ease-in-out;
		touch-action: none;
		height: 100%;

		&.no-select {
			user-select: none;
		}
	}
}
</style>
