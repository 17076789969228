import { useStore } from "vuex";

export const useGizmoStore = defineStore("gizmo", () => {
	const editingEnabled = ref(true);
	const layoutResizing = ref(false);
	const currentEditingLayoutWidth = ref("");
	const loggedIn = ref(false);
	const showAllSegmentedContent = ref(false);
	const labels = ref(true);
	const dragType = ref("");
	const getLinkCallback = ref(null);
	const getImageCallback = ref(null);

	const vuexStore = useStore();

	const setSegments = (segments: Array<string>) => {
		vuexStore.commit("setSegments", segments);
	};

	const spoofUserAgent = (userAgent: string) => {
		vuexStore.commit("setUserAgent", userAgent);
	};

	const handleLinkCallback = async (result: object) => {
		if (getLinkCallback.value) {
			await getLinkCallback.value(result);
		}

		getLinkCallback.value = null;
	}

	const handleImageCallback = async (result: object) => {
		if (getImageCallback.value) {
			await getImageCallback.value(result);
		}

		getImageCallback.value = null;
	}

	return {
		editingEnabled,
		layoutResizing,
		currentEditingLayoutWidth,
		loggedIn,
		showAllSegmentedContent,
		labels,
		dragType,
		setSegments,
		spoofUserAgent,
		handleImageCallback,
		handleLinkCallback,
		getImageCallback,
		getLinkCallback
	};
});
