import universalConfig from "~/telenorid/universal.config";
import { useUserStore } from "~/pinia/platform/user/user.ts";

// Necessary to make this work in Nuxt (has to support client/server)
// eslint-disable-next-line import/no-mutable-exports
let mgr;
let user;

let signInPromise;

const CALLBACK_STATE_KEY = "telenorIdLoginCallbackState";
const CALLBACK_URL_KEY = "telenorIdLoginCallbackUrl";

import { UserManager } from "oidc-client-ts";

if (import.meta.server) {
	// Nuxt
	mgr = {
		getUser: function () {},
		getOrLoginUser: function () {},
		loginRedirectBackHere: function () {},
		loginCallbackState: {},
		clearCallbackState: function () {},
		signinPopup: function () {},
		getUserFromState: function () {},
		autoSignIn: function () {},
		getOrLoginUserFromTelenorId: function () {},
	};
} else {
	// Client only
	if (!mgr) {
		const config = universalConfig(window.location.host);

		mgr = new UserManager(config);

		const loginCallbackState = localStorage.getItem(CALLBACK_STATE_KEY);
		try {
			if (loginCallbackState && loginCallbackState !== "undefined" && loginCallbackState !== "")
				mgr.loginCallbackState = JSON.parse(loginCallbackState);
		} catch (err) {
			console.warn(err);
		}

		mgr.clearCallbackState = async function () {
			localStorage.removeItem(CALLBACK_STATE_KEY);
		};

		mgr.setCallbackRedirectUrl = async function (url) {
			localStorage.setItem(CALLBACK_URL_KEY, url || window.location.href.replace(window.location.origin, ""));
		};

		mgr.loginRedirectBackHere = async function (state, aal3 = false, url) {
			localStorage.setItem(CALLBACK_URL_KEY, url || window.location.href.replace(window.location.origin, ""));
			localStorage.setItem(CALLBACK_STATE_KEY, JSON.stringify(state));
			if (aal3) {
				window.location.href = "/login?aal3=true";
			} else {
				window.location.href = "/login";
			}
		};

		mgr.loginRedirectTo = async function (href, state, aal3 = false) {
			localStorage.setItem(CALLBACK_URL_KEY, href);
			localStorage.setItem(CALLBACK_STATE_KEY, JSON.stringify(state));
			if (aal3) {
				window.location.href = "/login?aal3=true";
			} else {
				window.location.href = "/login";
			}
		};

		mgr.getTelenorUser = async function () {
			const userStore = useUserStore();
			const userFromState = userStore.customer;
			if (userFromState) return userFromState;

			return mgr.getUser();
		};

		mgr.getOrLoginUser = async function (force) {
			const userStore = useUserStore();
			const userFromState = userStore.customer;
			if (userFromState?.access_token) return userFromState;

			user = await mgr.getUser();
			if (user) {
				console.log("Returning user from memory");
				return user;
			}

			if (!signInPromise || force) {
				//TODO: I don't dare to play around with this now. Not sure how abiding this rule will affect things.
				// We have an iPhone to sell... [Boye]

				// eslint-disable-next-line no-async-promise-executor
				signInPromise = new Promise(async (resolve) => {
					try {
						user = await mgr.signinSilent();
					} catch {
						// The user is not logged in, and silent SSO fails. Ignore this error.
					}
					resolve(user);
				}).then((user) => {
					if (user) signInPromise = null;
					return user;
				});
			}

			return signInPromise;
		};

		mgr.properlyRemoveUser = function () {
			signInPromise = null;
			user = null;
			return mgr.removeUser();
		};

		mgr.getUserFromState = function () {
			const userStore = useUserStore();
			const userFromState = userStore.customer;
			if (userFromState?.access_token) return userFromState;
		};

		mgr.getOrLoginUserFromTelenorId = async function () {
			let user = await mgr.getUser();

			if (user) return user;

			return mgr.signinSilent();
		};

		mgr.autoSignIn = async function (username) {
			window.location.href = `/autoSignIn?username=${username}&callback=${window.location.href}`;
		};
	}
}

export default mgr;
