const SELF_INSTALL_CLASSIFICATION_ID = "466";

export function getAntennaProduct<T extends Product>(broadbandProduct: T): T | undefined {
	return broadbandProduct?.additionalProducts?.find((addon) => addon && addon.productType === "ANTENNE");
}

export function getBindingPaymentOption<T extends Product>(product: T): PaymentOption[] {
	return (
		product?.bindingsWithDiscounts?.map((binding) => ({
			...binding,
			amount: product?.prices?.ONE_TIME?.amount - Math.abs(binding?.discountedPrices?.ONE_TIME?.amount),
			discount: binding?.discountedPrices?.ONE_TIME?.amount,
			gizmo_text: "12 md avtaleperiode",
			gizmo_description: "12 mnd. binding",
		})) || []
	);
}

export function getLumpSumPaymentOption<T extends Product>(antenna: T): PaymentOption {
	return {
		...antenna?.prices?.ONE_TIME,
		productId: "one_time",
		gizmo_text: "Uten avtaleperiode",
		gizmo_description: "",
	};
}

export function getProductById<T extends Product>(id: string, products: T[]): T | undefined {
	return products.find((product) => product.productId === id);
}

export function isInstallationProduct<T extends Product>(product: T): boolean {
	return product?.productType === "INSTALLATION";
}

export function isSelfInstallProduct<T extends Product>(product: T): boolean {
	if (!product || !product.classifications) {
		return false;
	}
	return product.classifications.some(
		(classification) => classification.classificationId === SELF_INSTALL_CLASSIFICATION_ID,
	);
}

type PaymentOption = {
	binding?: BindingWithDiscount;
	productId: string;
	amount: number;
	discount: number;
	gizmo_text: string;
	gizmo_description: string;
};

interface Product {
	productId: string;
	productType: string;
	classifications: {
		classificationId: string;
	}[];
	prices: {
		[key: string]: Price;
	};
	bindingsWithDiscounts?: BindingWithDiscount[];
	additionalProducts?: Product[];
}

type Price = {
	priceType: "ONE_TIME" | "MONTHLY" | "DELIVERY" | "POINTS";
	description: string;
	amount: number;
	currency: "NOK" | "POINTS";
};

type BindingWithDiscount = {
	productId: string;
	id?: string;
	productName?: string;
	discountedPrices: {
		[key: string]: Price;
	};
	terminationCost?: number;
	orderable: boolean;
	endDate: string;
	renewalDate: string;
};
