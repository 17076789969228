import apigeeIntegration from "./self-service-adapter";
import systemUserIntegration from "./self-service-system-adapter";
import * as querystring from "~/helpers/http/querystring";

const api = apigeeIntegration("/mobile-subscription/v1");
const systemUserApi = systemUserIntegration("/mobile-subscription/v1");

const getSubscriptions = () => api.get("/");
const getSubscriptionAlternatives = (userId) =>
	api.get(`/new/alternatives?portfolio=TELENORCONSUMER${userId ? "&userId=" + userId : ""}`);
const getSubscriptionChangeAlternatives = (endUserId) => api.get(`/tel:${endUserId}/alternatives`);

const getConsumerFamilyEligiblePersons = () => api.get("/consumer-family/eligible-persons");

const getSubscriptionBySubscriptionId = (subscriptionId) =>
	api.get(`/internal/subscription-id/${subscriptionId}/basic`);
const getSubscriptionByMsisdn = (msisdn) => {
	if (msisdn.indexOf("tel:") !== 0) msisdn = "tel:" + msisdn;
	return api.get(msisdn);
};
const getSubscriptionPricesByMsisdn = (msisdn) => {
	if (msisdn.indexOf("tel:") !== 0) msisdn = "tel:" + msisdn;
	return api.get(msisdn + "/prices");
};
const getCalculationDetails = (subscriptionId) =>
	api.get(`/consumer-family/calculator/${subscriptionId}/calculation-details`);
const isValidTelenorNumber = (number) => api.get(`/new/alternatives/${number}/numbervalidator`);
const isAvailablePorting = (number) => api.get(`/msisdn/${number}/available-porting`);

const isValidTelenorNumberForLoggedOutUsers = (number) =>
	systemUserApi.get(`/new/alternatives/${number}/numbervalidator`);
const getCalculationDetailsForLoggedOutUsers = (subscriptionId) =>
	systemUserApi.get(`/consumer-family/calculator/${subscriptionId}/calculation-details`);

const getSubscriptionAlternativesForLoggedOutUsers = () =>
	systemUserApi.get("/new/alternatives?portfolio=TELENORCONSUMER");

const getNumbers = (productId) =>
	api.get(`/new/msisdns?subscriptionProductId=${productId}`, {
		cache: { override: true },
	});
const getNumbersForLoggedOutUsers = (productId) =>
	systemUserApi.get(`/new/msisdns?subscriptionProductId=${productId}`, {
		cache: { override: true },
		headers: {
			"x-apicache-bypass": true,
		},
	});

const getUserOfSubscriptionWithMsisdn = (msisdn) => api.get(`/tel:${msisdn}/user`);

// const activateSim = async sim => response; // mock for testing
const activateSim = async (sim) => api.put(`/tel:${sim.msisdnForSimCard}/simcards`, querystring.stringify(sim));

const orderSimcardReplacement = async (payload) =>
	api.post(`/tel:${payload.msisdnForSimCard}/simcards`, querystring.stringify(payload));

const getSimcardForms = () => api.get(`/simcards/forms`);

const getSimcardsForMsisdn = (msisdn) => api.get(`/tel:${msisdn}/simcards`);

const barrSubscription = (msisdnOrSim, pin) =>
	api.post(`/tel:${msisdnOrSim}/barring`, null, {
		headers: {
			"X-Telenor-Barring-Pin": pin,
		},
	});

const unbarrSubscription = (msisdnOrSim, pin) =>
	api.delete(`/tel:${msisdnOrSim}/barring`, {
		headers: {
			"X-Telenor-Barring-Pin": pin,
		},
	});

const orderAddonProducts = (msisdn, productsToAdd, productsToRemove) =>
	api.post(
		"/orders",
		{
			type: "CHANGE_ADDON_PRODUCTS",
			msisdn,
			productsToAdd,
			productsToRemove,
		},
		{
			cache: { override: true },
		},
	);

const getQrCode = (msisdn, iccId) =>
	api.get(`/tel:${msisdn}/simcards/iccids/${iccId}/esim/qrcodes`, {
		responseType: "arraybuffer",
		escapeSessionUpdateResponseCodes: [40104],
	});

const setMobileSubscriptionSimName = (msisdn, name) => {
	const payload = {
		type: "CHANGE_PERSONAL_NAME_FOR_SIM",
		msisdnOfSimCard: String(msisdn),
		personalName: name,
	};
	return api.post("/orders", payload);
};

export default {
	getSubscriptions,
	getConsumerFamilyEligiblePersons,
	getSubscriptionAlternatives,
	getCalculationDetails,
	getCalculationDetailsForLoggedOutUsers,
	getSubscriptionChangeAlternatives,
	getSubscriptionBySubscriptionId,
	getSubscriptionByMsisdn,
	getSubscriptionPricesByMsisdn,
	isValidTelenorNumber,
	isAvailablePorting,
	isValidTelenorNumberForLoggedOutUsers,
	getSubscriptionAlternativesForLoggedOutUsers,
	getNumbers,
	getNumbersForLoggedOutUsers,
	getUserOfSubscriptionWithMsisdn,
	activateSim,
	orderSimcardReplacement,
	getSimcardForms,
	barrSubscription,
	unbarrSubscription,
	orderAddonProducts,
	getQrCode,
	setMobileSubscriptionSimName,
	getSimcardsForMsisdn,
};
