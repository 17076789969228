import CartIntegration from "~/integrations/cart-integration";

const cartIntegration = CartIntegration();
import { v4 as uuid } from "uuid";
import ShoppingCartItemModel from "~/helpers/ecommerce/ShoppingCartItemModel/ShoppingCartItemModel.js";
import { isEqual, merge, mergeWith, unionBy } from "~/helpers/object-utility.js";
import { isAccessory } from "~/helpers/ecommerce/hardwareOrderHelper";
import {
	updateProductTitle,
	mapProduct,
	updateProductGradingValue,
} from "~/components/ecommerce/helpers/ecommerceTrackingHelper";
import dateHelper from "~/helpers/dateHelper";
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";

function getDeepKeys(obj) {
	var keys = [];
	for (var key in obj) {
		keys.push(key);
		if (typeof obj[key] === "object") {
			var subkeys = getDeepKeys(obj[key]);
			keys = keys.concat(
				subkeys.map(function (subkey) {
					return key + "." + subkey;
				}),
			);
		}
	}
	return keys;
}

let initPromise = null;

export const state = () => {
	return {
		items: [],
		paymentMethod: {},
		open: false,
		loading: true,
		emptied: false,
		loadingValidation: false,
		validation: null,
		swapProduct: null,
		loadingSwap: false,
		isAddingToCart: false,
		syncItem: null,
		selectedShoppingCartOption: "FULL_PRICE",
		subscriptions: null,
	};
};
export const mutations = {
	close(state) {
		state.open = false;
	},
	open(state) {
		state.open = true;
	},
	setEmptied(state, value) {
		state.emptied = value;
	},
	setIsAddingToCart(state, value) {
		state.isAddingToCart = value;
	},
	setValidation(state, validation) {
		state.validation = validation;
	},
	setSubscriptions(state, subscriptions) {
		state.subscriptions = subscriptions;
	},
	setLoadingSwap(state, value) {
		state.loadingSwap = value;
	},
	setSwapProduct(state, value) {
		state.swapProduct = value;
	},
	setSelectedShoppingCartOption(state, value) {
		state.selectedShoppingCartOption = value;
	},
	toggle(state) {
		state.open = !state.open;
	},
	remove(state, itemId) {
		const item = state.items.find((i) => i.id === itemId);

		state.items = state.items.filter((i) => i.id !== itemId);

		const products = mapProduct(
			item.metadata.category,
			`${updateProductTitle(item.metadata.name)} ${
				item.type === "hardware" && item.metadata?.description ? updateProductTitle(item.metadata.description) : ""
			}`,
			item.data.id,
			Math.round(item.metadata?.salePrice) || Math.round(item.metadata?.price),
			item.metadata.brand,
			item.metadata.name,
			item.metadata.memory,
			item.metadata.color,
			item.metadata.subCategory,
			null,
			null,
			updateProductGradingValue(item.metadata?.condition),
		);
		this.$track({
			event_name: "Product removed from shopping cart",
			sc_events: { scRemove: 1 },
			hardwareProductId: item.fallbackId,
			products,
			productCategories: [item.metadata?.category?.replaceAll(",", "")],
			productNames: [
				`${updateProductTitle(item.metadata.name)} ${
					item.type === "hardware" && item.metadata?.description ? updateProductTitle(item.metadata.description) : ""
				}`,
			],
			productIDs: [item.data.id],
			productCounts: [1],
			productPrices: [Math.round(item.metadata.salePrice) || Math.round(item.metadata.price)],
		});

		if (state.items.length === 0) state.open = false;
	},
	setLoadingValidation(state, value) {
		state.loadingValidation = value;
	},
	setItems(state, items) {
		state.items = items;
	},
	setPaymentMethod(state, paymentMethod) {
		state.paymentMethod = paymentMethod;
	},
	setSyncItem(state, syncItem) {
		state.syncItem = syncItem;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
};
export const actions = {
	async simpleAdd({ commit }, item) {
		const items = await cartIntegration.addItem(item);
		commit("setItems", items);
	},

	async addPaymentObject({ dispatch }, paymentObject) {
		dispatch("simpleAdd", paymentObject);
	},
	async add({ commit, dispatch, state, rootGetters }, item) {
		if (state.syncItem && item.metadata.category === ProductCategory.ACCESSORY) {
			state.syncItem = null;
		}
		const products = mapProduct(
			item.metadata.category ?? "Abonnement",
			`${updateProductTitle(item.metadata.name)} ${
				item.type === "hardware" && item.metadata?.description ? updateProductTitle(item.metadata.description) : ""
			}`,
			item.data.id,
			Math.round(item.metadata?.salePrice) || Math.round(item.metadata?.price),
			item.metadata.brand,
			item.metadata.name,
			item.metadata.memory,
			item.metadata.color,
			item.metadata.subCategory,
			null,
			null,
			updateProductGradingValue(item.metadata?.condition),
		);

		if (!item.doNotTrack) {
			// eslint-disable-next-line no-undef
			$nuxt.vueApp.config.globalProperties.$track({
				event_name: "Product added to shopping cart",
				sc_events: { scAdd: 1 },
				hardwareProductId: item.fallbackId,
				products,
				productCategories: [item.metadata?.category],
				productNames: [
					`${updateProductTitle(item.metadata.name)} ${
						item.type === "hardware" && item.metadata?.description ? updateProductTitle(item.metadata.description) : ""
					}`,
				],
				productIDs: [item.data?.id],
				productCounts: [1],
				productPrices: [Math.round(item.metadata?.salePrice) || Math.round(item.metadata?.price)],
			});
		}

		const isAccessoryAndNotOnlyAccessoryProducts = isAccessory(item) && !rootGetters["checkout/onlyAccessoryProducts"];
		if ((item?.type !== "hardware" || isAccessoryAndNotOnlyAccessoryProducts) && state.items?.length) {
			let resolvedItem = state.items.find((stateItem) => stateItem.id === item.id); // Double check that it's in the cart
			if (resolvedItem) {
				resolvedItem = JSON.parse(JSON.stringify(resolvedItem));
				// removing duplicate subItems
				resolvedItem.subItems = resolvedItem.subItems?.filter(
					(subItem) => subItem?.id !== item?.data?.id && subItem?.id !== item?.overrideSubItemId,
				);
				// adding new id to allow removing the sub-item.
				resolvedItem.subItems.push({ ...item, id: uuid() });
				item = resolvedItem;
			}
		}
		const addingProperty =
			getDeepKeys(state?.items?.find((existingItem) => item?.id === existingItem?.id))?.length <=
			getDeepKeys(item)?.length;

		const isNewDevice = [
			ProductCategory.PHONE,
			ProductCategory.GOOD_AS_NEW,
			ProductCategory.WATCH,
			ProductCategory.TABLET,
			ProductCategory.ROUTER,
		].includes(item.metadata.category);

		if (addingProperty && !isNewDevice) {
			// sync with store to prevent async updates overwriting eachother
			dispatch("setMergeSyncItem", item);
		} else {
			commit("setSyncItem", item);
		}
		const itemToAdd = addingProperty ? state.syncItem : item;
		const items = await cartIntegration.addItem(itemToAdd);
		commit("setItems", items);
		commit("open");
		commit("setEmptied", false);
	},
	async addSwapPendingDevice({ commit }, item) {
		// eslint-disable-next-line no-undef
		$nuxt.vueApp.config.globalProperties.$track({
			event_name: "Added pending swap to shopping cart",
			sc_events: { scAdd: 1 },
			productNames: [`${item.metaData?.parameters?.deviceInfo?.name?.replaceAll(",", "")}`],
			productCounts: [1],
			productType: "pending-swap-item",
		});

		commit("setSyncItem", item);

		const items = await cartIntegration.addItem(item);
		commit("setItems", items);
		commit("open");
		commit("setEmptied", false);
	},

	setLoadingValidation({ commit }, value) {
		commit("setLoadingValidation", value);
	},
	setValidation({ commit }, validation) {
		commit("setValidation", validation);
	},
	setSubscriptions({ commit }, subscriptions) {
		commit("setSubscriptions", subscriptions);
	},
	setMergeSyncItem({ state, commit }, item) {
		const customizer = (a, b) => {
			if (Array.isArray(a) || Array.isArray(b)) {
				// prioritize newly added item
				const uniqueIds = unionBy(b, a, "id");
				const listsMerged = uniqueIds.map((item) => {
					const firstListItem = a?.find((a_item) => a_item?.id === item?.id);
					const secondListItem = b?.find((b_item) => b_item?.id === item?.id);
					// merge objects from both lists
					return merge(firstListItem, secondListItem);
				});
				//remove duplicate item types except hardware
				return listsMerged.reduce(
					(acc, curr) =>
						!acc?.some((item) => item?.type === curr?.type) || curr?.type === "hardware" ? [...acc, curr] : acc,
					[],
				);
			} else {
				return a === b ? a : typeof a === "object" && typeof b === "object" ? merge(a, b) : b || a;
			}
		};

		if (!state.syncItem) {
			commit("setSyncItem", item);
		} else {
			const existingSyncItem = { ...(state.syncItem || {}) };
			if (state.syncItem && !isEqual(existingSyncItem, item)) {
				const merged = mergeWith(existingSyncItem, item, customizer);
				commit("setSyncItem", merged);
			}
		}
	},
	removeIdFromSyncItem({ commit, state }, itemId) {
		const removingRootProduct = state?.syncItem?.id === itemId;
		if (removingRootProduct) {
			commit("setSyncItem", null);
		} else if (state?.syncItem) {
			const subItemExceptItemId = state?.syncItem?.subItems?.filter((subItem) => subItem?.id !== itemId);
			commit("setSyncItem", { ...state?.syncItem, subItems: subItemExceptItemId });
		}
	},
	async init({ commit }) {
		if (initPromise) return initPromise;
		try {
			commit("setLoading", true);
			initPromise = await cartIntegration.getCartContent();
			commit("setItems", initPromise);
		} catch (err) {
			console.error(err);
		} finally {
			commit("setLoading", false);
		}
	},
	async remove({ dispatch, commit, state, getters, rootGetters }, itemId) {
		const resolvedRootItem = state.items.find((i) => i?.id === itemId);
		if (resolvedRootItem) {
			const items = await cartIntegration.removeItem(itemId);
			commit("setItems", items);

			if (
				getters.visibleCartItems.length === 1 &&
				!state.loading &&
				rootGetters["checkout/tradeInItem"]?.metaData &&
				window.location.href.includes("/checkout/")
			) {
				window.location.href = "/";
			}

			if (getters.visibleCartItems.length < 1 && !state.loading) {
				await dispatch("emptyCart");
			}
		} else {
			let resolvedSubItemParent = state.items.find((i) => i.subItems?.some((subItem) => subItem.id === itemId));
			const removingSubscription =
				resolvedSubItemParent?.subItems?.find((subItem) => subItem.id === itemId)?.type === "subscription";
			const subItemSiblingId = (resolvedSubItemParent?.subItems || [])?.find(
				(subItem) => subItem.id === itemId && subItem?.metadata?.siblingItemId,
			)?.metadata?.siblingItemId;
			if (resolvedSubItemParent) {
				resolvedSubItemParent = JSON.parse(JSON.stringify(resolvedSubItemParent));
				const filterSubItemsAndSiblings = (subItem) =>
					subItem.id !== itemId &&
					subItem.id !== subItemSiblingId &&
					!(removingSubscription && subItem?.type === "agreement");
				resolvedSubItemParent.subItems = resolvedSubItemParent.subItems.filter(filterSubItemsAndSiblings);
				commit("setSyncItem", resolvedSubItemParent);
				const items = await cartIntegration.addItem(resolvedSubItemParent);
				commit("setItems", items);
			} else {
				throw new Error(`Could not find item with id ${itemId} to remove`);
			}
		}
	},
	async removeSimple({ dispatch, commit, state, getters }, itemId) {
		const resolvedRootItem = state.items.find((i) => i?.id === itemId);
		if (resolvedRootItem) {
			const items = await cartIntegration.removeItem(itemId);
			commit("setItems", items);
			if (getters.visibleCartItems.length < 1 && !state.loading) {
				await dispatch("emptyCart");
			}
		} else {
			let resolvedSubItemParent = state.items.find((i) => i.subItems?.some((subItem) => subItem.id === itemId));
			if (resolvedSubItemParent) {
				resolvedSubItemParent = JSON.parse(JSON.stringify(resolvedSubItemParent));
				const removeSubItems = (subItem) => subItem.id !== itemId;
				resolvedSubItemParent.subItems = resolvedSubItemParent.subItems.filter(removeSubItems);
				const items = await cartIntegration.addItem(resolvedSubItemParent);
				commit("setItems", items);
			} else {
				throw new Error(`Could not find item with id ${itemId} to remove`);
			}
		}
	},
	async emptyCart({ commit }) {
		try {
			commit("setLoading", true);
			await cartIntegration.emptyCart();
			commit("setItems", []);
			commit("setSyncItem", null);
			commit("setEmptied", true);
			commit("close");
			commit("setLoading", false);
		} catch (e) {
			console.error(e);
		}
	},
	setPaymentMethod({ commit }, paymentMethod) {
		commit("setPaymentMethod", paymentMethod);
	},
	async setMetadata({ state, dispatch, commit }, newMetadata, doNotTrack = true) {
		const terminals = [state.items.filter((item) => item.type === "hardware")?.[0]];
		try {
			const existingMetadata = terminals?.length ? terminals?.[0]?.metadata : {};
			const updatedMetadata = { ...(existingMetadata || {}), ...newMetadata };
			if (!isEqual(existingMetadata, updatedMetadata)) {
				commit("setLoading", true);
				await Promise.all(
					terminals.map(async (terminal) => {
						const { type, data, subItems, id } = terminal ? { ...terminal } : {};
						await dispatch("add", {
							...ShoppingCartItemModel(type, data, updatedMetadata, id, subItems),
							doNotTrack,
						});
					}),
				);
			}
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoading", false);
		}
	},
};
export const getters = {
	contextItem(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/currentTerminal"];
	},
	visibleCartItems(state) {
		return state.items?.filter((item) => item.metadata || item.metaData) || [];
	},
	visibleSubscriptionsInCart(state, getters) {
		return getters.visibleCartItems?.[0]?.subItems?.filter(
			(item) => item.type === "subscription" || item.type === "msisdn",
		);
	},
	shoppingCartMemberPrice(state, getters) {
		const memberPriceCampaignData = getters.contextItem?.metadata?.memberPrice;
		const memberPrice = getters.isVatExempt
			? getters.contextItem?.metadata?.memberPrice?.prices?.withoutVat
			: getters.contextItem?.metadata?.memberPrice?.prices?.withVat;
		const startDate = memberPriceCampaignData?.startDate;
		const endDate = memberPriceCampaignData?.endDate;
		const hasActiveMemberPrice =
			!!startDate && !!endDate && dateHelper.todayIsBetween(new Date(startDate), new Date(endDate));
		return hasActiveMemberPrice ? { ...memberPriceCampaignData, ...memberPrice } : undefined;
	},
	eligibleMemberPrice(state, getters) {
		return { ...getters.shoppingCartMemberPrice, productId: getters.contextItem?.data?.id };
	},
	isVatExempt(state, getters, rootState, rootGetters) {
		const currentTerminal = rootGetters["checkout/currentTerminal"];
		return !!currentTerminal?.metadata?.isVatExempt;
	},
	currentTerminal(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/currentTerminal"];
	},
	subscriptions(state) {
		return state.subscriptions;
	},
	standaloneProducts(state) {
		const standaloneProducts = [];

		const add = (item) => {
			if (item.type !== "hardware") return;
			standaloneProducts.push({
				hardwareProductId: item.data.id,
				price: item.metadata.salePrice || item.metadata.price,
				quantity: 1,
			});
		};

		state.items?.forEach((item) => {
			add(item);
			item?.subItems?.forEach((subItem) => add(subItem));
		});

		return standaloneProducts;
	},
	paymentMethod(state) {
		return state.paymentMethod;
	},
	validatedSubscriptionPrice(state, getters) {
		let changedSubscription = state.validation?.changedSubscriptions?.[0]?.productChanges?.find(
			(changes) => changes?.productCategory === "SUBSCRIPTION",
		);
		let subscriptionInCart = getters?.contextItem?.subItems?.find((item) => item?.type === "subscription");
		// Checks if the validated subscriptionPrice matches the subscription ID and the MSISDN in the cart
		if (
			changedSubscription?.productId == subscriptionInCart?.metadata?.subscriptionProductId &&
			state.validation?.changedSubscriptions?.[0]?.msisdn ===
				getters.contextItem?.subItems
					?.find((item) => item?.type === "msisdn")
					?.metadata?.msisdnDetails?.msisdn?.replace(/^(?=.{10}$)47/, "")
		) {
			return state.validation?.changedSubscriptions?.[0]?.productChanges
				?.find((changes) => changes?.productCategory === "SUBSCRIPTION")
				?.prices?.find((price) => price?.type === "MONTHLY_LIST_PRICE")?.discountedPrice;
		}
		return undefined;
	},

	items(state) {
		return state.items;
	},
};
