import dealersearch from "../integrations/dealer-search";
import telenorbutikkenOpeninghours from "~/helpers/ecommerce/telenorbutikken-openinghours";
import {useGeolocationStore} from "~/pinia/platform/geolocation/geolocation.ts";

let existingPromise;

export default {
	state() {
		return {
			closestStore: null,
			override: false,
			loading: true,
		};
	},
	mutations: {
		clear(state) {
			window.sessionStorage.removeItem("closestStore");
			state.closestStore = null;
		},
		setOverride(state, override) {
			state.override = override;
			window.sessionStorage.setItem("overrideClosestStore", JSON.stringify(override));
		},
		setClosestStore(state, store) {
			store.openingHours = telenorbutikkenOpeninghours[store.dealerId];
			state.closestStore = store;
			window.sessionStorage.setItem("closestStore", JSON.stringify(store));
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async requestClosestStore({ dispatch, commit, rootState, rootGetters, state }, silent) {
			if (existingPromise) return existingPromise;
			else existingPromise = this;
			const closestStoreFromSession = JSON.parse(window.sessionStorage.getItem("closestStore") || false);
			const overrideFromSession = JSON.parse(window.sessionStorage.getItem("overrideClosestStore") || false);

			if (overrideFromSession && closestStoreFromSession) {
				if (state.loading) commit("setLoading", false);

				commit("setClosestStore", closestStoreFromSession);
				existingPromise = null;
				return;
			}

			if (!state.closestStore) commit("setLoading", true);

			await useGeolocationStore().requestBestAvailablePosition(silent);

			const position = useGeolocationStore().position;

			if (position?.latitude) {
				try {
					const stores = await dealersearch.getClosest(position.latitude, position.longitude);

					if (state.loading) commit("setLoading", false);

					if (stores.length > 0) {
						commit("setClosestStore", stores[0]);
						existingPromise = null;
						return stores[0];
					}
				} catch (err) {
					console.error(err);
				}
			} else if (rootState.customer) {
				// Get closest store from SAS
				/*try {
                    const sasStores = await sas.getClosestStore();

                    if (sasStores.length > 0) {
                        const matchingStoreFromDealerSearch = await dealersearch.getById(sasStores[0].id);

                        if (matchingStoreFromDealerSearch)
                            commit('setClosestStore', matchingStoreFromDealerSearch);
                    }
                } catch(err){
                    console.error(err);
                }*/
			}

			if (state.loading) commit("setLoading", false);

			existingPromise = null;
		},
	},
};

export const state = () => ({
	closestStore: null,
	override: false,
	loading: true,
});
export const actions = {
	async requestClosestStore({ dispatch, commit, rootState, rootGetters, state }, silent) {
		if (existingPromise) return existingPromise;
		else existingPromise = this;
		const closestStoreFromSession = JSON.parse(window.sessionStorage.getItem("closestStore") || false);
		const overrideFromSession = JSON.parse(window.sessionStorage.getItem("overrideClosestStore") || false);

		if (overrideFromSession && closestStoreFromSession) {
			if (state.loading) commit("setLoading", false);

			commit("setClosestStore", closestStoreFromSession);
			existingPromise = null;
			return;
		}

		if (!state.closestStore) commit("setLoading", true);

		await useGeolocationStore().requestBestAvailablePosition(silent);

		const position = useGeolocationStore().position;

		if (position?.latitude) {
			try {
				const stores = await dealersearch.getClosest(position.latitude, position.longitude);

				if (state.loading) commit("setLoading", false);

				if (stores.length > 0) {
					commit("setClosestStore", stores[0]);
					existingPromise = null;
					return stores[0];
				}
			} catch (err) {
				console.error(err);
			}
		} else if (rootState.customer) {
			// Get closest store from SAS
			/*try {
				const sasStores = await sas.getClosestStore();

				if (sasStores.length > 0) {
					const matchingStoreFromDealerSearch = await dealersearch.getById(sasStores[0].id);

					if (matchingStoreFromDealerSearch)
						commit('setClosestStore', matchingStoreFromDealerSearch);
				}
			} catch(err){
				console.error(err);
			}*/
		}

		if (state.loading) commit("setLoading", false);

		existingPromise = null;
	},
};
export const mutations = {
	clear(state) {
		window.sessionStorage.removeItem("closestStore");
		state.closestStore = null;
	},
	setOverride(state, override) {
		state.override = override;
		window.sessionStorage.setItem("overrideClosestStore", JSON.stringify(override));
	},
	setClosestStore(state, store) {
		store.openingHours = telenorbutikkenOpeninghours[store.dealerId];
		state.closestStore = store;
		window.sessionStorage.setItem("closestStore", JSON.stringify(store));
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
};
export const getters = {};
