const isProduction = (isClient = import.meta.client) => {
	return isClient && window.location.hostname.indexOf("test") < 0 && window.location.hostname.indexOf("localhost") < 0;
};

const apiUrl = (isClient) => {
	let apiUrl = "https://atest-api.telenor.no";

	if (isProduction(isClient)) {
		apiUrl = apiUrl.replace("atest-", "");
	}
	return apiUrl;
};

export default { apiUrl, isProduction };
