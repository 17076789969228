import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/consumer-fault-search/v1");

/**
 * Starts a flow process for a given subscription and flow ID.
 */
const startFlow = async (subscriptionId, flowId) => {
	if (!subscriptionId || !flowId) {
		throw new Error("Invalid parameters: 'subscriptionId' and 'flowId' are required.");
	}
	return handleRequest("GET", `subscription/${subscriptionId}/flow/${flowId}/start`);
};

/**
 * Advances a flow process with the required parameters.
 */
const advanceFlow = async (subscriptionId, flowId, stepId, sessionId, inputParameters, conclusion) => {
	if (!subscriptionId || !flowId || !stepId || !sessionId) {
		throw new Error("Invalid parameters: 'subscriptionId', 'flowId', 'stepId', and 'sessionId' are required.");
	}
	return handleRequest("PUT", `subscription/${subscriptionId}/flow/${flowId}/step`, {
		stepId,
		session: sessionId,
		inputParameters,
		conclusion,
	});
};

/**
 * Activates a data package for a given subscription and associated phone numbers.
 */
const activateDataPackage = async (subscriptionId, phoneNumbers) => {
	if (!subscriptionId || !Array.isArray(phoneNumbers) || phoneNumbers.length === 0) {
		throw new Error("Invalid parameters: 'subscriptionId' and a non-empty array of 'phoneNumbers' are required.");
	}
	return handleRequest("POST", `subscription/${subscriptionId}/activate_data_package`, { phoneNumbers });
};

/**
 * Handles API requests with proper error handling.
 */
const handleRequest = async (method, url, data = null) => {
	const upperMethod = method.toUpperCase();

	const requestMethods = {
		GET: () => api.get(url),
		PUT: () => api.put(url, data),
		POST: () => api.post(url, data),
	};

	if (!requestMethods[upperMethod]) {
		throw new Error(`Unsupported HTTP method: ${method}`);
	}

	try {
		return await requestMethods[upperMethod]();
	} catch (error) {
		console.error(`API request failed: ${upperMethod} ${url}`, error);
		return {
			success: false,
			error: error,
		};
	}
};

export default {
	startFlow,
	advanceFlow,
	activateDataPackage,
};
