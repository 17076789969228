import getTrackingContext from "./get-tracking-context.js";
import { trackingPluginExclusion as EXCLUDED_ROUTES, routeIsExcluded } from "../../config/excludedRoutes.js";
import {
	updateProductTitle,
	mapProduct,
	getSubCategory,
	updateProductGradingValue,
} from "~/components/ecommerce/helpers/ecommerceTrackingHelper.js";

import { usePageStore } from "~/pinia/platform/page.ts";
import { useUserStore } from "~/pinia/platform/user/user.ts";
import filterComponent from "~/helpers/segmentation/filterComponent.js";
import { useContactDetailsStore } from "~/pinia/platform/contact-details.js";

export default defineNuxtPlugin(async ({ $store, vueApp, $config }) => {
	const analytics = useAnalytics();

	const route = useRoute();

	const pageStore = usePageStore();

	let trackingContext = getTrackingContext({
		$store,
		config: $config.public,
		path: route.path,
	});

	const track = async (data) => {
		if (!trackingContext.use_tealium && $config.public.isPreview) return;

		const pageData = await getPageData(route);
		const trackingData = { ...pageData, ...data };
		sendTracking(trackingData);
	};

	vueApp.config.globalProperties.$track = track;

	const sendTracking = (value) => {
		return useAnalytics().custom.event({ details: value });
	};

	async function sendPageData(page) {
		if (routeIsExcluded(page, EXCLUDED_ROUTES, true)) return;

		console.log("Sending page data", page);
		const segmentedContent = usePageStore()
			.page?.layouts.filter((layout) => layout)
			.filter((layout) => filterComponent(layout, $store.state))
			.map((layout) => {
				return layout.columns.map((column) => {
					return column
						.filter((component) => component)
						.filter((component) => component.segmentation)
						.filter((component) => filterComponent(component, $store.state));
				});
			})
			.filter((layout) => layout.length > 0)
			.flat()
			.filter((column) => column.length > 0)
			.flat();

		let abTestVariant;

		if (pageStore.page.gizmoAbTest) {
			abTestVariant = {
				id: pageStore.page.gizmoAbTest.testId,
				index: pageStore.page.gizmoAbTest.testIndex,
			};
		}

		let segmentation = {
			segments: [], // TODO: Map to user's segments, once segmentation is back
			segmentedContentIds: segmentedContent.map((comp) => comp.id),
			segmentedComponentNames: segmentedContent.map((comp) => comp.schema?._id || comp.schema?.name),
		};

		useRuntimeHook("page:finish", async () => {
			if (usePageStore().page?.metadata?.disableAnalyticsPageLoad) {
				console.info(
					"Page loaded analytics disabled for this page. Not reporting to Tealium. Please check the page metadata if this is incorrect",
				);
				return;
			}

			if (analytics.hasProducts()) {
				let products = analytics.getProducts();
				products = await Promise.all(products);

				analytics.page.productLoaded({
					segmentation,
					abTestVariant,
					products,
				});

				analytics.resetProducts();
			} else {
				analytics.page.loaded({
					segmentation,
					abTestVariant,
				});
			}
		});
	}

	async function getPageData(page) {
		const userStore = useUserStore();

		if (userStore.customer && !useContactDetailsStore().contactDetails) {
			await useContactDetailsStore().fetchContactDetails();
		}

		trackingContext = getTrackingContext({
			$store,
			config: $config.public,
			path: page.path,
		});

		if (trackingContext.use_tealium) {
			const base = {
				server: trackingContext.server,
				site_section: trackingContext.site_section,
				page_name: trackingContext.page_name,
				page_hierarchy: trackingContext.page_hierarchy,
			};

			if (trackingContext.gizmo_segment) base.gizmo_segment = trackingContext.gizmo_segment;

			if (trackingContext.kurt) base.kurt = trackingContext.kurt;

			if (trackingContext.gizmo_segmented_content_ID)
				base.gizmo_segmented_content_ID = trackingContext.gizmo_segmented_content_ID;

			if (trackingContext.gizmo_segmented_component_name)
				base.gizmo_segmented_component_name = trackingContext.gizmo_segmented_component_name;

			if (trackingContext.hashed_emailAddress) base.hashed_emailAddress = trackingContext.hashed_emailAddress;

			if (trackingContext.hashed_phoneNumber) base.hashed_phoneNumber = trackingContext.hashed_phoneNumber;

			if ($store.state.ecommerce?.product?.id) {
				const product = $store.state.ecommerce?.product;

				const productTitle = updateProductTitle(product.title);

				base.inventory = product.inventory?.[0]?.amount;
				base.pageUrl = $config.public.domain + pageStore.page.publishUrl;
				base.categoryId = `${product.category} > ${product.brand}`;
				base.value = Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat);
				base.sc_events = {
					prodView: 1,
				};
				base.message = productTitle;

				base.productName = productTitle;
				base.hardwareProductId = product.fallbackId;

				base.products = mapProduct(
					product.category,
					productTitle,
					product.id,
					Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat),
					product.brand,
					product.cleanName,
					product.memory,
					product.color,
					getSubCategory(product.categories),
					null,
					null,
					updateProductGradingValue(product.condition),
				);
				base.productCategories = [product.category];
				base.productNames = [productTitle];
				base.productIDs = [product.id];
				base.productCounts = [1];
				base.productPrices = [Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat)];
			}

			return base;
		}
	}

	return {
		provide: {
			track,
			sendPageData,
		},
	};
});
