import filterComponent from "~/helpers/segmentation/filterComponent.js";
import hasSegmentation from "~/helpers/segmentation/hasSegmentation.js";

export default function useComponentSegmentation(component) {
	if (!component) return false;

	const { $store } = useNuxtApp();

	if ($store.getters["segmentationDisabled"] || !hasSegmentation(component)) return true;

	return filterComponent(component, $store.state);
}
