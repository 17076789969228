import type { InternalAxiosRequestConfig } from "axios";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getCacheKey = (config: CacheRequestConfig | InternalAxiosRequestConfig): string => {
	const { method, url, params, data, baseURL } = config;

	const fullUrl = baseURL + url;

	const urlWithParams = appendParamsToUrl(fullUrl, params);

	// By default, non-GET requests is not cached, but can be if specified in the request configuration.
	let bodyPart = "";
	if (data && (method === "post" || method === "put" || method === "patch")) {
		bodyPart = ":" + (typeof data === "string" ? data : JSON.stringify(data));
	}

	return `${method?.toUpperCase()}:${urlWithParams}${bodyPart}`;
};

const appendParamsToUrl = (url: string, params) => {
	if (!params) return url;

	const queryString = new URLSearchParams(params).toString();

	if (!queryString) return url;

	return `${url}?${queryString}`;
};
