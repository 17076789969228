import { ShoppingCartItemEnum } from "~/components/ecommerce/types/ShoppingCartItemEnum";
import type { ShoppingCartTradeInItem } from "~/components/hardware-and-insurance/types/ShoppingCartTradeInItem";
import type { TradeInInfo } from "~/components/hardware-and-insurance/types/TradeInInfo";

export const mapTradeInItem = ({ tradeInInfo }: { tradeInInfo?: TradeInInfo }): ShoppingCartTradeInItem => ({
	id: ShoppingCartItemEnum.TRADE_IN,
	type: ShoppingCartItemEnum.TRADE_IN,
	metaData: tradeInInfo,
	data: {},
	subItems: [],
});
