import { usePageStore } from "~/pinia/platform/page";
import { useFlowStore } from "~/pinia/platform/flow/flow";

/**
 * Custom composable to manage flow state and actions.
 * @beta
 *
 * @param {Object} options - Configuration options for the flow.
 * @param {boolean} [options.disableAutoInitGraph=false] - Whether to disable automatic graph initialization.
 * @returns {Object} - The flow state and actions.
 */

interface IFlowOptions {
	/** Disables injected onMounted hook which automatically tries to fetch a flow definition for the given page */
	disableAutoInitGraph?: boolean;
}

export function useFlow(options: IFlowOptions = { disableAutoInitGraph: false }) {
	const route = useRoute();
	const router = useRouter();

	const disableAutoInitGraph = options.disableAutoInitGraph || false;

	const pageStore = usePageStore();
	const flowStore = useFlowStore();

	async function initGraph() {
		try {
			await useFlowStore().init(pageStore.page.id);
		} catch (error) {
			console.error(error);
		}
	}

	function validateGraph() {
		const lastValidRoute = flowStore.pathTaken?.[flowStore.pathTaken?.length - 1];
		if (lastValidRoute !== route?.path && lastValidRoute) {
			router.push(lastValidRoute);
		}
	}

	function goBack() {
		if (flowStore.prevAction) {
			router.push(flowStore.prevAction);
		} else {
			router.push("/privat");
		}
	}

	function goToStart() {
		router.push(flowStore.firstAction);
	}

	onMounted(async () => {
		if (disableAutoInitGraph || pageStore.page?.metadata?.disableFlowFetch) return;

		try {
			await initGraph();
		} catch (err) {
			flowStore.error = err;
			flowStore.flowName = "hello";
		}
	});

	return {
		error: computed(() => flowStore.error),
		loading: computed(() => flowStore.loading),
		nextAction: computed(() => flowStore.nextAction),
		prevAction: computed(() => flowStore.prevAction),
		initGraph,
		validateGraph,
		goBack,
		goToStart,
	};
}
