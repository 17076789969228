import { toLatLon } from "utm/index.es";
import { useUserStore } from "~/pinia/platform/user/user";
import geolocationIntegration from "~/integrations/geolocation-integration";

import { search } from "~/ts-integrations/address-search-v1";
import { getLoggedInCustomer } from "~/ts-integrations/customers-v1";

import type { GetLoggedInCustomerResponse } from "~/ts-integrations/customers-v1";
type Address = GetLoggedInCustomerResponse["address"];

const UNSUPPORTED_PATHS = ["mitt-telenor/tv-internett/twe/innholds-velger/twe-box", "loginCallbackTweBox"];

const getDevicePosition = async (silent) => {
	let permitsGeolocation = {
		state: "prompt",
	};

	if (navigator.permissions) permitsGeolocation = await navigator.permissions.query({ name: "geolocation" });

	// Set or ask the user for permission to get location (if not silent)
	if (permitsGeolocation?.state === "granted" || !silent) {
		try {
			const position: GeolocationPosition = await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			});

			return position?.coords;
		} catch {
			// Geolocation query failed
			return false;
		}
	} else {
		return false;
	}
};

const getCoordinatesFromLoggedInUserData = async () => {
	const translateEPSG32633CoordinatesToLatLng = (x, y) => {
		try {
			return toLatLon(Number(x), Number(y), 33, "N", undefined, false);
		} catch {
			return {};
		}
	};

	const getCoordinatesFromAddress = async (address) => {
		const { data: result } = await search({ path: { searchString: address } });
		console.log("ADDRESS SEARCH RESULT: ", result);

		if (result && result.length === 1) {
			// API spec is garbage
			return translateEPSG32633CoordinatesToLatLng(result[0].coordX, result[0].coordY);
		}
	};

	const formatAddressString = (address: Address) =>
		`${address.streetName} ${address.streetNumber} ${address.postalPlace}`;

	try {
		const { data: customer, error } = await getLoggedInCustomer();
		const { address } = customer;
		if (address) {
			return getCoordinatesFromAddress(formatAddressString(address));
		}
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const useGeolocationStore = defineStore("geolocation", () => {
	const devicePosition = ref({
		latitude: 0.0,
		longitude: 0.0,
	});

	const customerPosition = ref({
		latitude: 0.0,
		longitude: 0.0,
	});

	const approximatePosition = ref({
		latitude: 0.0,
		longitude: 0.0,
	});

	const position = computed(() => {
		if (devicePosition.value.longitude) return devicePosition.value;
		if (customerPosition.value.longitude) return customerPosition.value;
		if (approximatePosition.value.longitude) return approximatePosition.value;
		return {};
	});

	async function requestDevicePosition(silent: boolean) {
		const position = await getDevicePosition(silent);

		if (position) {
			devicePosition.value = { latitude: position.latitude, longitude: position.longitude };
		}

		return position;
	}

	async function requestCustomerPosition() {
		const userStore = useUserStore();
		if (userStore.customer) {
			const coordinates = await getCoordinatesFromLoggedInUserData();
			if (coordinates) customerPosition.value = coordinates;
		}
	}

	async function requestApproximatePosition() {
		const position = await geolocationIntegration.getApproximateLocation();

		if (position) approximatePosition.value = { latitude: position.data.latitude, longitude: position.data.longitude };

		return position;
	}

	async function requestBestAvailablePosition(silent: boolean) {
		if (UNSUPPORTED_PATHS.some((path) => window.location.href.includes(path))) {
			return null;
		}

		if (devicePosition.value.latitude) return devicePosition.value;
		if (customerPosition.value.latitude) return devicePosition.value;

		await requestDevicePosition(silent);

		if (devicePosition.value.latitude) return devicePosition.value;

		await requestCustomerPosition();

		if (customerPosition.value.latitude) return customerPosition.value;

		await requestApproximatePosition();

		if (approximatePosition.value.latitude) return approximatePosition.value;
	}

	//
	//\
	//\\
	//\\\         I had to. It's beautiful 😭
	//\\\\
	//\\\\\
	return {
		position,
		devicePosition,
		customerPosition,
		approximatePosition,
		requestDevicePosition,
		requestCustomerPosition,
		requestApproximatePosition,
		requestBestAvailablePosition,
	};
});
