export const getters = {
	// EDGES FOR FLOW NAVIGATION //
	isImeiStepCompleted(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.imeiStepCompleted;
	},
	isGradingStepCompleted(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.gradingStepCompleted;
	},
	hasValidInsurance(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.hasValidInsurance;
	},
	wantsToUseInsurance(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.wantsToUseInsurance;
	},
	isTradeInCompleted(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.tradeInCompleted;
	},
	goToSummary(state, getters, rootState, rootGetters) {
		return rootGetters["checkout/tradeInItem"]?.metaData?.steps?.goToSummary;
	},
	notIsGradingStepCompleted(state, getters, rootState, rootGetters) {
		return !rootGetters["checkout/tradeInItem"]?.metaData?.steps?.gradingStepCompleted;
	},
};
