<script lang="ts" setup></script>

<template>
	<NuxtPage />
	<div
		id="tn-chat"
		class=""
		data-auto-open="false"
		data-auto-start="false"
	></div>
</template>
