import contentServiceIntegration from "~/integrations/content-service-integration";
import flowUtils from "~/pinia/helpers/flow/flowUtils";
import { useStore } from "vuex";

import type { S as VuexState } from "vuex";

/** Represents a node in a graph. Contains a reference to a page on Telenor.no. Connected using edges */
interface INode {
	id: NodeId;
	page?: {
		id: string;
		url: string;
	};
}

/** Represents a connection between two nodes in a graph. Allows the user in Gizmo to set a list of conditions that needs to be met before the user can traverse the line */
interface IEdge {
	id: string;
	source?: string;
	target?: string;
	config?: {
		/**
		 * TODO: NOT IMPLEMENTED
		 * Automatically follow this edge if all conditions are met (not requiring a "Next" click) */
		autoFollow?: boolean;
		/** Strings referring to either Pinia or Vuex state/getters */
		conditions?: string[];
	};
}

interface IGraphItem extends INode, IEdge {
	/** Internal reference to graph item */
	id: string;
}

interface NodesWithEdges {
	[key: NodeId]: {
		edges: IEdge[];
	};
}

type NodeId = string;

/** Represents a graph of nodes and edges. This is resolved by various functions in the store */
type Graph = Array<IGraphItem>;

/** A URL to a page on Telenor.no */
type PageUrl = string;

/** Root-relative reference to either Vuex a state/getter or Pinia state.
 * For example "mittTelenor/fixed/orderStatus" */
type StoreKey = string;

const getNodeById = (graph: Graph, id: NodeId): INode => graph?.find((node) => node.id === id);
const getPageUrlByNodeId = (graph: Graph, id: NodeId): PageUrl => getNodeById(graph, id)?.page?.url;

export const useFlowStore = defineStore("flow", () => {
	const pageStore = usePageStore();

	const pinia: any = usePinia(); // Used to access Pinia internals
	const vuexStore: any = useStore(); // Used to access Vuex internals

	const getFromVuexState = (state: VuexState, key: StoreKey) => {
		const levels = key.split("/");
		try {
			return levels.reduce((total, current) => total[current], state);
		} catch {
			// Probably hit a nullpointer
		}
	};

	const getFromPinia = (key: StoreKey) => {
		let levels = key.split("/");

		try {
			const state = pinia._s.get(levels[0]);
			levels = levels.slice(1);

			return levels.reduce((total, current) => total[current], state);
		} catch {
			// Probably hit a nullpointer
		}
	};

	const graph: Ref<Graph> = ref(<Graph>[]);
	const flowName = ref("");
	const error = ref<Error | null>(null);
	const loading = ref(false);

	const allNodes: ComputedRef<Array<INode>> = computed(() => {
		return graph.value?.filter((node) => !!node.page) || [];
	});

	const currentNode: ComputedRef<INode> = computed(() => {
		return allNodes.value.find((node) => node.page.id === pageStore.page.id) || null;
	});

	const allEdges: ComputedRef<Array<IEdge>> = computed(() => {
		return graph.value?.filter((edge) => !edge.page) || [];
	});

	const endNode: ComputedRef<INode> = computed(() => {
		return allNodes.value.filter((node) => !allEdges.value.map((edge) => edge.source).includes(node.id))?.[0] || null;
	});

	const startNode: ComputedRef<INode> = computed(() => {
		return allNodes.value.filter((node) => !allEdges.value.map((edge) => edge.target).includes(node.id))?.[0] || null;
	});

	const stateQualifiesForEdge = computed(() => {
		return (edge: IEdge): boolean => {
			if (!edge.config?.conditions) return true;
			return edge.config.conditions.every((condition: string) => {
				condition = flowUtils.convertNuxt2StorePathToNuxt3(condition);

				const negate = condition[0] === "!";
				if (negate) condition = condition.replace("!", "");

				const fromPinia = getFromPinia(condition);
				const fromVuexState = getFromVuexState(vuexStore.state, condition);
				const fromVuexGetters = vuexStore.getters[condition];

				let returnValue: any;
				if (fromPinia) returnValue = fromPinia;
				if (fromVuexState) returnValue = fromVuexState;
				if (fromVuexGetters) returnValue = fromVuexGetters;

				if (negate) returnValue = !returnValue;

				return returnValue;
			});
		};
	});

	/**
	 * Groups all Nodes (pages) with their outgoing edges (connections to other pages), sorted by weight.
	 * Edges with conditions are sorted by the number of conditions, where more conditions are considered heavier.
	 */
	const nodesWithRelatedEdges: ComputedRef<NodesWithEdges> = computed(() => {
		let adjacency = {};
		for (const node of allNodes.value) {
			const outgoingEdges = allEdges.value
				.filter((edge) => edge.source === node.id)
				.sort((aWeight, bWeight) => bWeight?.config?.conditions?.length - aWeight?.config?.conditions?.length);
			adjacency = {
				...adjacency,
				[node.id]: {
					edges: outgoingEdges,
				},
			};
		}
		return adjacency;
	});

	/**
	 * Returns a URL to the next page in the flow.
	 */
	const nextAction: ComputedRef<PageUrl> = computed(() => {
		if (!currentNode.value) return "";
		return (
			nodesWithRelatedEdges.value[currentNode.value.id]?.edges
				?.filter(stateQualifiesForEdge.value)
				?.map((next) => getPageUrlByNodeId(graph.value, next?.target))?.[0] || ""
		);
	});

	/**
	 * Returns a URL to the previous page in the flow.
	 */
	const prevAction: ComputedRef<PageUrl> = computed(() => {
		return pathTaken.value[pathTaken.value.length - 2] || "";
	});

	/**
	 * Returns the first URL in the flow.
	 */
	const firstAction: ComputedRef<PageUrl> = computed(() => {
		return pathTaken.value[0] || "";
	});

	/**
	 * Generates an array representing the path the user has already taken in the flow.
	 * This is used to determine the previous step in the flow.
	 */
	const pathTaken: ComputedRef<Array<PageUrl>> = computed(() => {
		if (!startNode.value || !currentNode.value) return [];

		let stack = [];
		const valid = [];
		const closed = [];
		let next;

		closed.push({ path: [startNode.value.id], weight: 0 });
		if (startNode.value?.id === currentNode.value.id) {
			valid.push({ edge: {}, path: [startNode.value.id], weight: 0 });
		} else {
			nodesWithRelatedEdges.value[startNode.value.id]?.edges?.forEach((edge) => {
				stack.push({
					edge: edge,
					path: [startNode.value.id, edge?.target],
					weight: 0 + (edge?.config?.conditions?.length || 0),
				});
			});
			while (stack.length > 0) {
				[next, ...stack] = stack;
				const ok = stateQualifiesForEdge.value(next?.edge);
				if (ok) {
					closed.push(next);
					if (next.edge.target === currentNode.value.id) {
						valid.push(next);
					} else {
						nodesWithRelatedEdges.value[next?.edge?.target]?.edges?.forEach((edge) => {
							stack.push({
								edge: edge,
								path: [...next.path, edge?.target],
								weight: (edge?.config?.conditions?.length || 0) + next?.weight,
							});
						});
					}
				}
			}
		}
		if (valid.length > 0) {
			if (valid.length === 1) {
				return valid.flatMap((found) => found.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)));
			} else {
				return valid
					.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((found) => found?.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)));
			}
		} else {
			if (closed.length === 1) {
				return closed?.flatMap((mostValid) =>
					mostValid?.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)),
				);
			} else {
				return closed
					.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((mostValid) => mostValid?.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)));
			}
		}
	});

	/**
	 * Generates an array representing the full path of pages the user is bound for in the flow.
	 * This can be used to determine progress in the flow, but it is currently not in use.
	 */
	const currentTrajectory: ComputedRef<Array<PageUrl>> = computed(() => {
		if (!endNode.value) return [];

		const lastValidPath = pathTaken.value[pathTaken.value.length - 1];
		const lastValidNodeId = allNodes.value.find((node) => node?.page?.url === lastValidPath)?.id;
		let stack = [];
		const valid = [];
		const closed = [];
		let next;
		// return [];
		if (lastValidNodeId === endNode.value.id) {
			return pathTaken.value;
		} else {
			nodesWithRelatedEdges.value[lastValidNodeId]?.edges?.forEach((edge) => {
				stack.push({
					edge: edge,
					path: [edge?.target],
					weight: edge?.config?.conditions?.length || 0,
				});
			});
			while (stack.length > 0) {
				[next, ...stack] = stack;
				closed.push(next);
				if (next?.edge?.target === endNode.value.id) {
					valid.push(next);
				} else {
					nodesWithRelatedEdges.value[next?.edge?.target]?.edges?.forEach((edge) => {
						stack.push({
							edge: edge,
							path: [...next.path, edge?.target],
							weight: (edge?.config?.conditions?.length || 0) + next?.weight,
						});
					});
				}
			}
			if (valid?.length === 1) {
				const continuation = valid?.flatMap((found) =>
					found?.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)),
				);
				return [...pathTaken.value, ...continuation];
			} else {
				const continuation = valid
					?.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr?.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((found) => found?.path?.map((nodeid) => getPageUrlByNodeId(graph.value, nodeid)));
				return [...pathTaken.value, ...continuation];
			}
		}
	});

	/**
	 * Fetches the flow definition for the given page. Uses the current page if no pageId is provided.
	 */
	async function init(pageId?: string) {
		if (!import.meta.client) return;
		loading.value = true;
		try {
			const flowDefinition = await contentServiceIntegration.getFlow(pageId || pageStore.page?.id);
			graph.value = flowDefinition?.metadata?.graph?.graphData2;
			flowName.value = flowDefinition?.metadata?.flowName || "";
		} catch (err) {
			error.value = err;
		} finally {
			loading.value = false;
		}
	}

	return {
		graph,
		flowName,
		error,
		loading,
		allNodes,
		currentNode,
		allEdges,
		endNode,
		startNode,
		stateQualifiesForEdge,
		nodesWithRelatedEdges,
		nextAction,
		pathTaken,
		currentTrajectory,
		prevAction,
		firstAction,
		init,
	};
});
