import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/id-guard/v1");

const getTexts = (locale = "no_nb") => api.get(`/language/?locale=${locale}`);

const getAssets = () => api.get(`/self-service/assets`);
const getLeakedAssets = () => api.get(`/self-service/leaks`);
const getSingleAsset = (assetId) => api.get(`/self-service/assets/${assetId}`);
const createAsset = (asset, type = "Email") => api.post(`/self-service/assets`, { value: asset, type: type });
const deleteAsset = (assetId) => api.delete(`/self-service/assets/${assetId}`);
const getAssetBreaches = (assetId) => api.get(`/self-service/assets/${assetId}`);
const verifyAsset = (tokenId) => api.put("/self-service/assets", { token: tokenId });

const getSpecifiedMatches = (matchIds) => api.get(`/self-service/matches/specified/${matchIds}`);
const verifyMathces = (matchIds, isRead = true) =>
	api.put("/self-service/leaks/acknowledge", { matchIds: matchIds, isRead: isRead });

const getLeaksByDomain = (read) =>
	api.get(`/self-service/leaks/domain?read=${read}`, {
		cache: {
			override: true,
		},
	});
const getSpecificLeaks = (matchIds) => api.post(`/self-service/leaks/specific`, { matchIds: matchIds });

const getStatus = () => api.get("/self-service/status");

export default {
	getTexts,
	getAssets,
	getLeakedAssets,
	getSingleAsset,
	createAsset,
	deleteAsset,
	getAssetBreaches,
	getSpecifiedMatches,
	verifyMathces,
	getStatus,
	verifyAsset,
	getLeaksByDomain,
	getSpecificLeaks,
};
