export default function addQueryParams(queryParams: { path: string; content?: string | boolean }[], path: string) {
	let newPath = path;
	if (queryParams.length > 0) {
		queryParams
			.filter(({ path, content }) => path && content)
			.forEach((param, index) => {
				if (index === 0) {
					newPath += `?${param.path}=${param.content}`;
				} else {
					newPath += `&${param.path}=${param.content}`;
				}
			});
	}
	console.log(newPath);
	return newPath;
}
