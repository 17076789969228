import { useUserStore } from "~/pinia/platform/user/user.ts";

export const state = () => {
	return {
		isPreview: false,
		showSpacing: false,
		siteSegment: "",
		selectTriggerElement: false,
		testIndex: undefined,
		testType: 0,
		host: "",
		errorPages: {},
		execSegmentation: true,
		termsAccepted: false,
		isTest: false,
		faroUrl: "",
		isMobile: false,
		globals: {
			privat: {
				header: null,
				footer: null,
			},
			bedrift: {
				header: null,
				footer: null,
			},
		},
		isAutoScrolling: false,
		activeIndex: undefined,
	};
};
export const mutations = {
	setAutoScrolling(state, isAutoScrolling) {
		state.isAutoScrolling = isAutoScrolling;
	},
	setActiveIndex(state, activeIndex) {
		state.activeIndex = activeIndex;
	},
	setFaroUrl(state, faroUrl) {
		state.faroUrl = faroUrl;
	},
	setGlobals(state, globals) {
		state.globals[globals.segment] = globals.globals;
	},
	setTermsAccepted(state, termsAccepted) {
		state.termsAccepted = termsAccepted;
	},
	setSegments(segments) {
		const userStore = useUserStore();
		userStore.customer = { ...userStore.customer, segments };
		// TODO: Move to actions in gizmo.js
	},
	setTargetServerState(state, serverState) {
		// TODO: Remove (move to module)
		state.targetServerState = serverState;
	},
	setMobile(state, isMobile) {
		state.isMobile = isMobile;
	},
	setLocals(state, locals) {
		state.selectTriggerElement = locals.selectTriggerElement;
		state.testIndex = locals.testIndex;
		state.testType = locals.testType;
		state.host = locals.host;
	},
	setHost(state, host) {
		state.host = host;
	},
	setIsTest(state, isTest) {
		state.isTest = isTest;
	},
	setPreview(state) {
		state.previewMode = true;
	},
	setSiteSegment(state, segment) {
		// This is not a getter because in search, you can toggle the segment without manipulating the URL
		state.siteSegment = segment;
	},
	setSelectTriggerElement(state, selectTriggerElement) {
		// TODO: Move to Gizmo module
		state.selectTriggerElement = selectTriggerElement;
	},
	setTestIndex(state, testIndex) {
		// TODO: Move to Gizmo module
		state.testIndex = testIndex;
	},
	setTestType(state, testType) {
		// TODO: Move to Gizmo module
		state.testType = testType;
	},

	setExecSegmentation(state, execSegmentation) {
		state.execSegmentation = execSegmentation;
	},
	setDynamicAnalyticsData(state, data) {
		state.dynamicAnalyticsData = data;
	},
	toggleShowSpacing(state) {
		state.showSpacing = !state.showSpacing;
	},
};
export const actions = {
	setAutoScrolling({ commit }, isAutoScrolling) {
		commit("setAutoScrolling", isAutoScrolling);
	},
	setActiveIndex({ commit }, activeIndex) {
		commit("setActiveIndex", activeIndex);
	},
};
export const getters = {
	getAutoScrolling: (state) => state.isAutoScrolling,
	getActiveIndex: (state) => state.activeIndex,
	getErrorPage: (state) => (statusCode) => state.errorPages[statusCode],
	getGlobalSegment: (state) => state.siteSegment,
	segments: () => {
		const userStore = useUserStore();
		return userStore.customer?.segments || [];
	},
	segmentationDisabled: (state) => {
		return [state.gizmo?.showAllSegmentedContent, state.previewMode, state.gizmo?.dragType === "component"].some(
			(e) => e,
		);
	},
	isMobile: (state) => {
		return state.isMobile;
	},
};
