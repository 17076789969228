import apigeeIntegration from "./self-service-adapter";
import systemUserApigeeIntegration from "./self-service-system-adapter";
import stbSelfServiceAdapter from "./stb-self-service-adapter";
import type { TvContentProvider, TvContentCompensation } from "~/types/tvAndInternet/tv/tvContent";
import type { ExtendedAxiosInstance } from "~/types/requests";

const api: ExtendedAxiosInstance = apigeeIntegration("/tv-content/v1");
const systemUserApi: ExtendedAxiosInstance = systemUserApigeeIntegration("/tv-content/v1");
const stbApiIntegration: ExtendedAxiosInstance = stbSelfServiceAdapter("/tv-content/v1");

const getCompensationOffer = async (id: string): Promise<TvContentCompensation> => api.get(`/${id}/compensation/offer`);

const getTvContent = async () => systemUserApi.get<TvContentProvider[]>("/");
const getPremiumTvContent = async () => api.get("/premium");
const getSubscriptionTvContent = async (id: string) =>
	api.get(`/${id}`, {
		cache: { override: true },
	});
const getSubscriptionRentedMovies = async (id: string) => api.get(`/${id}/movies`);

const getDefaultSetupDetailed = () => api.get("/defaultsetup/detailed");
const getChannel = async (id: string) => api.get(`/packages/${id}`);

//STB endpoints
const getSubscriptionTvContentStb = async () => stbApiIntegration.get(`/stb`);
const validateSelectedContentStb = async (data) => stbApiIntegration.put(`/stb/validator`, { contents: data });
const updateSelectedContentStb = async (data, smsCode) =>
	stbApiIntegration.put(`/stb`, { contents: data, pin: smsCode });
const getTvContentThirdPartyStatusByProductIdStb = async (productId: string) => {
	return stbApiIntegration.get(`/stb/third-party/status/?marketProductIdFilter=${productId}`, {
		cache: { override: true },
	});
};

const validateSelectedContent = async (id, data) => api.put(`/${id}/validator`, { contents: data });
const updateSelectedContent = async (id, data) => api.put(`/${id}`, { contents: data });

const resetToDefaultSetup = async (id, data) => api.put(`/${id}/reset`, { contents: data });
const validateDefaultSetup = async (id, data) => api.put(`/${id}/reset/validator`, { contents: data });

const validateSelectedFrihetProducts = async (id, data) =>
	api.put(`/pointflex/${id}/validator`, data, { timeout: 35000 });
const updateSelectedFrihetProducts = async (id, data) => api.put(`/pointflex/${id}`, data, { timeout: 35000 });
const getFrihetFreightCost = async (id, data) => api.put(`/pointflex/${id}/freightcost`, data, { timeout: 35000 });

const getNotifications = async (id: string) => api.get(`/${id}/notifications`);

const getNetflixSubscriptionStatus = async (id: string) => api.get(`/netflix/${id}/subscription-status`);
const getNetflixUrls = async (id: string) => api.get(`/netflix/${id}/urls`);

const getTvContentThirdParty = async () => api.get("/third-party");

const getTvContentThirdPartyStatus = async (id: string) =>
	api.get(`/third-party/status/${id}`, {
		cache: { override: true },
	});

const getTvContentThirdPartyStatusByProductId = async (subscriptionId, productId) => {
	return api.get(`/third-party/status/${subscriptionId}?marketProductIdFilter=${productId}`, {
		cache: { override: true },
	});
};

const getTvContentThirdPartyStatusByProviderName = async (subscriptionId, providerName) => {
	return api.get(`/third-party/status/${subscriptionId}?providerFilter=${providerName}`, {
		cache: { override: true },
	});
};

const getTvContentThirdPartyStatusByProviderId = async (subscriptionId, providerId) => {
	return api.get(`/third-party/status/${subscriptionId}?providerIdFilter=${providerId}`, {
		cache: { override: true },
	});
};

export default {
	getCompensationOffer,
	getTvContent,
	getDefaultSetupDetailed,
	getChannel,
	getSubscriptionTvContent,
	getSubscriptionTvContentStb,
	validateSelectedContent,
	updateSelectedContent,
	resetToDefaultSetup,
	validateDefaultSetup,
	validateSelectedFrihetProducts,
	updateSelectedFrihetProducts,
	getFrihetFreightCost,
	getNotifications,
	getSubscriptionRentedMovies,
	getPremiumTvContent,
	validateSelectedContentStb,
	updateSelectedContentStb,
	getNetflixSubscriptionStatus,
	getNetflixUrls,
	getTvContentThirdPartyStatus,
	getTvContentThirdParty,
	getTvContentThirdPartyStatusByProductId,
	getTvContentThirdPartyStatusByProviderName,
	getTvContentThirdPartyStatusByProductIdStb,
	getTvContentThirdPartyStatusByProviderId,
};
