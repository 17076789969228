import { defineStore } from "pinia";

export interface IField {
	uid?: string;
	elementId?: string;
	validate?: () => void; // set optional
	name: string;
	value: unknown;
	[key: string]: unknown;
}

export interface IForm {
	requiredFields: IField[];
	formSubmitted: boolean;
	totalFileSize: number;
	values: Record<string, unknown>;
	submitting: boolean;
}

export const useFormStore = defineStore("form", () => {
	const form = ref<IForm>({
		requiredFields: [],
		formSubmitted: false,
		totalFileSize: 0,
		values: {},
		submitting: false,
	});

	const setFormSubmitted = () => {
		form.value.formSubmitted = true;
	};

	const addTotalFileSize = (fileSize: number) => {
		form.value.totalFileSize += fileSize;
	};

	const subtractTotalFileSize = (fileSize: number) => {
		form.value.totalFileSize -= fileSize;
	};

	const resetTotalFileSize = () => {
		form.value.totalFileSize = 0;
	};

	const addRequiredFormField = (field: IField) => {
		if (!form.value.requiredFields.some((item) => item.uid === field.uid)) {
			form.value.requiredFields.push(field);
			form.value.requiredFields.sort((a, b) => (a.uid > b.uid ? 1 : -1));
		}
	};

	const removeRequiredFormField = (uid: string) => {
		form.value.requiredFields = form.value.requiredFields.filter((item) => item.uid !== uid);
	};

	const resetRequiredFormFields = () => {
		form.value.requiredFields = [];
	};

	const setFormFieldValue = (field: IField) => {
		form.value.values[field.name] = field.value;
	};

	return {
		form,
		setFormSubmitted,
		addTotalFileSize,
		subtractTotalFileSize,
		resetTotalFileSize,
		addRequiredFormField,
		removeRequiredFormField,
		resetRequiredFormFields,
		setFormFieldValue,
	};
});
