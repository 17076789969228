import type { ModalInfo } from "~/components/platform/globals/Modal/Modal";

const showModal: Ref<boolean> = ref(false);
const modalInfo: Ref<ModalInfo | undefined> = ref(undefined);

/**
 * Composable function to manage modal dialogs.
 *
 * @returns {Object} - Methods and properties to manage modals.
 */
export default function useModal(): {
	openModal: (info: ModalInfo) => void;
	closeModal: () => void;
	modalInfo: Ref<ModalInfo | undefined>;
	showModal: Ref<boolean>;
} {
	/**
	 * Opens the modal with the provided information.
	 *
	 * @param {ModalInfo} info - Information about the modal to be displayed.
	 *
	 * @example
	 * openModal({
	 * 			heading: "Test",
	 * 			subHeading: "Her tester vi
	 * 			actions: [
	 * 				{
	 * 					props: { text: "Lukk" },
	 * 					action: () => {
	 * 						closeModal();
	 * 					},
	 * 				},
	 * 			],
	 * 		});
	 */
	const openModal = (info: ModalInfo) => {
		showModal.value = true;
		modalInfo.value = info;
		console.log("open modal");
	};

	/**
	 * Closes the currently open modal.
	 */
	const closeModal = () => {
		showModal.value = false;
	};

	// Watch for changes in showModal and reset modalInfo when the modal is closed
	watch(showModal, (value) => {
		if (!value) {
			modalInfo.value = undefined;
		}
	});

	return {
		openModal,
		closeModal,
		modalInfo,
		showModal,
	};
}
