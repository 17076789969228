import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_swVfBKW2I2W72wvHYb03YLfqZkpByfdEdB0ymgNPzNo from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/nuxt3-vuex-module/src/plugin.js";
import tracking_plugin_client_NF_MVDl91CmYzQ4hm5Fobu8uORfFqmRRh3Mv2AaVwkk from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/tracking/tracking-plugin.client.js";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/analytics.client.ts";
import client_init_client_z2uVBy2cIabg7502_KWPc6o_Ve0a1H33EGlWg55Y8vc from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/client-init.client.js";
import content_plugin_L7YczLzM8Zf2GU1h7wm7_rRMbEb0RidTheahubz_F_U from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/content-plugin.js";
import editable_string_directive_m9vQZ1NgcEmcZPQpRMRmkP56JLhKr8WEQIn3k4YSFkw from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/editable-string-directive.js";
import expose_nuxt_client_Pbp_ry3vnyxRwdSGrYSsBK2_vIkRjn85JKFfjcYHQIY from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/expose-nuxt.client.js";
import inline_editing_bootstrap_client_ZFwYOpHhy8i8ZqAbMSM7e0h1y5h2rua8EmQP0A1OhCY from "/home/runner/work/s02679-telenor-no/s02679-telenor-no/plugins/inline-editing-bootstrap.client.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  plugin_swVfBKW2I2W72wvHYb03YLfqZkpByfdEdB0ymgNPzNo,
  tracking_plugin_client_NF_MVDl91CmYzQ4hm5Fobu8uORfFqmRRh3Mv2AaVwkk,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U,
  client_init_client_z2uVBy2cIabg7502_KWPc6o_Ve0a1H33EGlWg55Y8vc,
  content_plugin_L7YczLzM8Zf2GU1h7wm7_rRMbEb0RidTheahubz_F_U,
  editable_string_directive_m9vQZ1NgcEmcZPQpRMRmkP56JLhKr8WEQIn3k4YSFkw,
  expose_nuxt_client_Pbp_ry3vnyxRwdSGrYSsBK2_vIkRjn85JKFfjcYHQIY,
  inline_editing_bootstrap_client_ZFwYOpHhy8i8ZqAbMSM7e0h1y5h2rua8EmQP0A1OhCY
]