export const getInsuranceClaimLink = ({ useRedirectLink, imei, insuranceClaimLinkForRedirect, insuranceClaimLink }) => {
	window.location.href = useRedirectLink && imei ? `${insuranceClaimLinkForRedirect}${imei}` : insuranceClaimLink;
};

export const getBenefits = ({ benefits, isDownPayment, isScreen }) => {
	if (isDownPayment) {
		return benefits?.downPaymentInsurance;
	} else {
		if (isScreen) {
			return benefits?.fullPriceScreenInsurance;
		} else {
			return benefits?.fullPriceInsurance;
		}
	}
};

export const getInsuranceExtraInfoText = ({ selectedInfoText, poid }) => {
	return selectedInfoText?.find((info) => info?.poid === poid)?.infoText;
};
